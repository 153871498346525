import React, { useState } from "react";
import { COLORS } from "../../constants";
import { ICONS } from "../../icons";
import IconButton from "../IconButton";

const Input = ({
  label = "",
  value = "",
  placeHolder = "",
  onChange,
  name,
  className = "",
  error = false,
  helperText,
  fullWidth = false,
  allowCopy = false,
  bold = false,
  ...props
}) => {
  const [copyIcon, setCopyIcon] = useState(ICONS.contentCopy);

  const handleCopy = () => {
    navigator.clipboard.writeText(value);
    setCopyIcon(ICONS.copied);
    setTimeout(() => {
      setCopyIcon(ICONS.contentCopy);
    }, 1000);
  };

  return (
    <div className={`form-item ${error ? "error" : ""} ${className}`}>
      <label>{label}</label>
      <input
        className={`input-control ${fullWidth ? "full-width" : ""} ${
          bold ? "bold" : ""
        }`}
        placeholder={placeHolder}
        name={name}
        value={value}
        onChange={onChange}
        {...props}
      />
      {error && ICONS.error}
      {allowCopy && (
        <IconButton
          type="button"
          icon={copyIcon}
          onClick={handleCopy}
          iconColor={{
            normal: COLORS.bright,
            hover: COLORS.light,
          }}
        />
      )}
      {error && helperText && <span>{helperText}</span>}
    </div>
  );
};

export default Input;
