import React from "react";
import BackButton from "../components/BackButton";
import Input from "../components/form-controls/Input";
import Layout from "../components/layout/Layout";
import PrimaryButton from "../components/PrimaryButton";
import { COLORS } from "../constants";

const Summary = () => {
  return (
    <Layout
      secondHalf={{
        bgColor: COLORS.neutral,
        content: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="682"
            height="222"
            viewBox="0 0 682 222"
            fill="none"
            id="summary-svg"
          >
            <g id="Property 1=Default">
              <g id="handGrabingCard">
                <path
                  id="Vector"
                  d="M499.291 119.022L536.92 122.628C536.92 122.628 544.247 120.55 535.761 110.057C534.998 109.112 534.108 108.106 533.073 107.023L512.882 99.5692L512.886 98.1531L513.009 65.9188L513.055 53.4428L499.666 42.964L497.109 40.9618L470.812 76.5804L471.081 76.9891L486.807 100.416L499.291 119.022Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_2"
                  d="M511.648 107.752L549.277 111.358C549.817 111.205 550.321 110.926 550.756 110.54C551.192 110.156 551.55 109.671 551.804 109.12C552.059 108.569 552.206 107.965 552.236 107.346C552.265 106.728 552.177 106.109 551.975 105.531C551.653 104.376 551.179 103.28 550.568 102.279C549.092 99.8868 547.367 97.6959 545.429 95.7533L525.239 88.2994L525.309 69.7345L525.342 60.4767L525.365 54.491L525.411 42.1726L509.466 29.6915L496.026 47.8908L483.168 65.3106L485.65 69.0027L488.61 73.4172L502.814 94.5897L505.708 98.902L511.648 107.752Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_3"
                  d="M447.406 111.017C447.406 111.017 460.956 107.92 464.799 96.8395C465.997 93.1857 466.141 89.204 465.211 85.4537L469.27 72.3366L475.045 53.6486L494.842 47.0079L496.026 47.8901L512.493 60.1691L517.53 63.9262L525.31 69.7339L563.036 97.8669C564.702 100.319 566.718 102.45 569.002 104.176C571.108 105.822 573.373 107.201 575.754 108.288C582.681 111.455 592.668 113.216 606.248 109.423L681.598 119.888L682 64.5286L618.276 51.4991L520.822 0.00500488L462.586 26.399L440.061 74.9121L447.406 111.017Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_4"
                  opacity="0.15"
                  d="M529.003 108.435L529.384 106.962L505.485 99.2715L497.525 79.1149L504.72 70.5954L503.716 69.5436L495.896 78.8027L504.484 100.546L529.003 108.435Z"
                  fill="black"
                />
                <path
                  id="Vector_5"
                  opacity="0.1"
                  d="M550.569 102.279L556.887 104.588L569.002 104.177C571.108 105.823 573.373 107.202 575.754 108.289L586.178 106.306L603.787 102.996L536.989 55.3448L525.343 60.4769C525.343 60.4769 551.291 103.52 550.569 102.279Z"
                  fill="black"
                />
                <path
                  id="Vector_6"
                  opacity="0.15"
                  d="M459.287 93.8901C459.49 93.7851 464.278 91.2706 465.262 86.1226C465.922 82.6631 464.693 78.9185 461.611 74.9936L460.573 76.0039C463.352 79.54 464.476 82.8334 463.915 85.7934C463.082 90.1865 458.75 92.4754 458.707 92.4981L459.287 93.8901Z"
                  fill="black"
                />
                <path
                  id="Vector_7"
                  opacity="0.15"
                  d="M525.045 92.147L549.515 101.089L549.086 102.547L524.616 93.6043L525.045 92.147Z"
                  fill="black"
                />
                <path
                  id="Vector_8"
                  opacity="0.15"
                  d="M529.255 55.5781L495.006 46.2623L494.679 47.7528L524.275 55.8033L501.029 64.4005L501.465 65.8562L529.255 55.5781Z"
                  fill="black"
                />
              </g>
              <g id="handHoldingCard">
                <path
                  id="Vector_9"
                  d="M183.23 169.172L145.638 173.23C145.638 173.23 138.292 171.241 146.676 160.646C147.429 159.691 148.31 158.674 149.334 157.58L169.452 149.884L169.433 148.467L168.997 116.235L168.83 103.761L182.115 93.1216L184.652 91.0882L211.295 126.39L211.029 126.802L195.534 150.417L183.23 169.172Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_10"
                  d="M170.764 158.049L133.171 162.107C132.631 161.961 132.124 161.688 131.684 161.308C131.245 160.928 130.883 160.448 130.623 159.9C130.363 159.353 130.21 158.75 130.174 158.131C130.138 157.512 130.221 156.892 130.417 156.312C130.729 155.154 131.191 154.053 131.791 153.044C133.245 150.633 134.948 148.422 136.867 146.456L156.984 138.761L156.733 120.197L156.609 110.94L156.528 104.955L156.362 92.6367L172.185 79.9652L185.803 98.0019L198.829 115.267L196.384 118.989L193.466 123.437L179.469 144.781L176.618 149.128L170.764 158.049Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_11"
                  d="M235.037 160.543C235.037 160.543 221.456 157.608 217.506 146.576C216.272 142.935 216.089 138.956 216.982 135.194L212.796 122.127L206.839 103.508L186.978 97.1059L185.802 98.0027L169.457 110.48L164.456 114.297L156.733 120.198L119.284 148.784C117.641 151.256 115.646 153.41 113.379 155.163C111.288 156.835 109.037 158.241 106.666 159.356C99.7708 162.607 89.8013 164.487 76.1854 160.858L0.941268 172.228L0 116.877L63.5941 103.081L160.542 50.4173L219.032 76.1108L242.029 124.352L235.037 160.543Z"
                  fill="#FFB7B7"
                />
                <g id="creditCard">
                  <path
                    id="Vector_12"
                    d="M458.442 44.9253L203.524 9.20439C202.544 9.06831 201.555 9.37055 200.775 10.0446C199.995 10.7187 199.487 11.7096 199.362 12.8001L180.275 180.86C180.152 181.95 180.423 183.051 181.027 183.918C181.633 184.787 182.523 185.353 183.502 185.49L438.422 221.212C439.402 221.348 440.389 221.046 441.17 220.372C441.95 219.698 442.459 218.706 442.584 217.616L461.671 49.5564C461.793 48.4656 461.522 47.3656 460.917 46.4974C460.311 45.6292 459.422 45.0639 458.442 44.9253Z"
                    fill="#2A063D"
                  />
                  <path
                    id="Vector_13"
                    d="M458.442 44.9253L203.524 9.20439C202.544 9.06831 201.555 9.37055 200.775 10.0446C199.995 10.7187 199.487 11.7096 199.362 12.8001L180.275 180.86C180.152 181.95 180.423 183.051 181.027 183.918C181.633 184.787 182.523 185.353 183.502 185.49L438.422 221.212C439.402 221.348 440.389 221.046 441.17 220.372C441.95 219.698 442.459 218.706 442.584 217.616L461.671 49.5564C461.793 48.4656 461.522 47.3656 460.917 46.4974C460.311 45.6292 459.422 45.0639 458.442 44.9253ZM441.527 217.468C441.439 218.247 441.076 218.956 440.518 219.437C439.961 219.919 439.255 220.135 438.555 220.037L183.636 184.315C182.936 184.218 182.3 183.813 181.868 183.193C181.435 182.573 181.242 181.787 181.33 181.008L200.417 12.9479C200.506 12.1689 200.869 11.4607 201.426 10.9791C201.984 10.4975 202.689 10.282 203.389 10.3796L458.31 46.1006C459.01 46.199 459.644 46.6027 460.078 47.2229C460.509 47.8431 460.702 48.6292 460.614 49.4084L441.527 217.468Z"
                    fill="#3F3D56"
                  />
                  <path
                    id="Vector_14"
                    d="M225.724 35.5998C224.47 35.4243 223.293 34.8391 222.34 33.9184C221.387 32.9976 220.699 31.7825 220.366 30.427C220.034 29.0713 220.07 27.6358 220.468 26.3023C220.868 24.9686 221.614 23.7968 222.612 22.9346C223.609 22.0726 224.814 21.5592 226.074 21.4593C227.333 21.3593 228.592 21.6774 229.688 22.3733C230.785 23.0691 231.67 24.1115 232.235 25.3687C232.8 26.6258 233.015 28.0412 232.857 29.4358C232.645 31.306 231.774 33.0061 230.436 34.162C229.099 35.318 227.404 35.8351 225.724 35.5998ZM227.191 22.6721C226.148 22.5258 225.087 22.7275 224.147 23.2516C223.206 23.7757 222.425 24.5985 221.904 25.6163C221.382 26.634 221.144 27.801 221.218 28.9694C221.292 30.1378 221.675 31.2552 222.321 32.1805C222.966 33.1057 223.842 33.7972 224.84 34.1674C225.837 34.5376 226.913 34.57 227.927 34.2603C228.942 33.9507 229.852 33.313 230.542 32.4279C231.231 31.5428 231.67 30.4501 231.802 29.288C231.89 28.5163 231.84 27.733 231.656 26.9828C231.471 26.2328 231.157 25.5305 230.728 24.9162C230.3 24.3018 229.767 23.7874 229.16 23.4025C228.553 23.0175 227.885 22.7693 227.191 22.6721Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_15"
                    d="M216.681 27.1684C216.833 25.8234 217.359 24.5647 218.185 23.5678C219.011 22.5706 220.095 21.885 221.286 21.6063C220.451 21.1478 219.527 20.9187 218.596 20.9377C217.663 20.9567 216.749 21.2235 215.927 21.7157C215.106 22.2081 214.402 22.9116 213.875 23.7677C213.347 24.6239 213.011 25.608 212.893 26.6378C212.777 27.6677 212.883 28.7138 213.203 29.6886C213.522 30.6636 214.047 31.5391 214.732 32.2425C215.418 32.9459 216.244 33.4567 217.142 33.7323C218.042 34.008 218.987 34.0405 219.899 33.8271C218.807 33.2283 217.91 32.2649 217.333 31.0714C216.756 29.8779 216.528 28.5136 216.681 27.1684Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_16"
                    d="M242.736 115.457C241.477 115.278 240.334 114.552 239.556 113.435C238.778 112.32 238.429 110.905 238.587 109.503L240.388 93.6359C240.549 92.2354 241.203 90.9625 242.206 90.097C243.209 89.2314 244.48 88.844 245.738 89.02C246.998 89.1959 248.141 89.9224 248.919 91.0378C249.697 92.1516 250.046 93.5664 249.889 94.9683L248.086 110.834C247.927 112.236 247.273 113.51 246.27 114.376C245.266 115.243 243.996 115.631 242.736 115.457Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_17"
                    d="M255.402 117.231C254.144 117.052 252.999 116.326 252.221 115.209C251.443 114.094 251.096 112.679 251.253 111.276L253.055 95.41C253.216 94.0095 253.869 92.7353 254.872 91.8697C255.876 91.0041 257.146 90.6182 258.405 90.7941C259.664 90.9701 260.808 91.6952 261.586 92.8105C262.364 93.9258 262.713 95.3405 262.555 96.741L260.753 112.608C260.592 114.01 259.938 115.283 258.936 116.15C257.933 117.017 256.662 117.406 255.402 117.231Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_18"
                    d="M268.069 119.004C266.81 118.827 265.667 118.1 264.889 116.984C264.111 115.867 263.762 114.454 263.92 113.05L265.723 97.1846C265.882 95.784 266.536 94.5098 267.539 93.6442C268.542 92.7786 269.813 92.3913 271.071 92.5686C272.331 92.7446 273.474 93.4697 274.254 94.585C275.032 95.7003 275.38 97.115 275.222 98.5156L273.421 114.381C273.26 115.783 272.606 117.057 271.603 117.924C270.6 118.791 269.329 119.18 268.069 119.004Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_19"
                    d="M287.599 121.741C286.339 121.562 285.194 120.835 284.416 119.72C283.638 118.603 283.291 117.189 283.448 115.787L285.251 99.9212C285.412 98.5193 286.064 97.2464 287.068 96.3809C288.071 95.5153 289.341 95.1279 290.601 95.3039C291.859 95.4798 293.004 96.2063 293.782 97.3216C294.56 98.437 294.908 99.8503 294.751 101.252L292.949 117.118C292.788 118.52 292.135 119.794 291.131 120.661C290.128 121.527 288.858 121.915 287.599 121.741Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_20"
                    d="M300.265 123.519C299.005 123.34 297.862 122.614 297.084 121.497C296.306 120.382 295.957 118.967 296.116 117.565L297.917 101.7C298.078 100.298 298.732 99.0248 299.735 98.1592C300.737 97.2937 302.008 96.9063 303.267 97.0822C304.526 97.2582 305.67 97.9847 306.449 99.1C307.227 100.214 307.574 101.629 307.417 103.031L305.616 118.896C305.455 120.298 304.801 121.572 303.798 122.438C302.795 123.305 301.524 123.694 300.265 123.519Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_21"
                    d="M312.932 125.293C311.672 125.115 310.527 124.388 309.749 123.271C308.971 122.156 308.624 120.741 308.781 119.339L310.584 103.472C310.745 102.072 311.397 100.798 312.401 99.932C313.404 99.0664 314.674 98.6804 315.934 98.8564C317.192 99.0323 318.337 99.7574 319.115 100.873C319.893 101.988 320.241 103.403 320.084 104.805L318.282 120.67C318.121 122.072 317.468 123.346 316.464 124.212C315.461 125.079 314.191 125.468 312.932 125.293Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_22"
                    d="M332.46 128.029C331.201 127.852 330.057 127.124 329.279 126.008C328.501 124.892 328.153 123.477 328.31 122.075L330.113 106.209C330.273 104.807 330.927 103.535 331.929 102.669C332.934 101.803 334.203 101.416 335.462 101.592C336.721 101.769 337.866 102.495 338.644 103.61C339.422 104.725 339.771 106.138 339.612 107.54L337.811 123.406C337.65 124.808 336.996 126.082 335.993 126.949C334.99 127.815 333.719 128.204 332.46 128.029Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_23"
                    d="M345.126 129.803C343.866 129.624 342.723 128.898 341.945 127.782C341.167 126.666 340.818 125.251 340.977 123.849L342.778 107.983C342.939 106.582 343.593 105.309 344.596 104.443C345.599 103.578 346.87 103.19 348.128 103.366C349.386 103.543 350.531 104.269 351.309 105.384C352.087 106.499 352.436 107.913 352.279 109.314L350.476 125.18C350.315 126.582 349.662 127.856 348.66 128.723C347.655 129.589 346.386 129.978 345.126 129.803Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_24"
                    d="M357.793 131.579C356.534 131.4 355.39 130.674 354.612 129.557C353.834 128.442 353.486 127.027 353.643 125.625L355.446 109.76C355.606 108.358 356.26 107.085 357.262 106.219C358.267 105.354 359.536 104.966 360.796 105.142C362.054 105.318 363.199 106.045 363.977 107.16C364.755 108.275 365.104 109.689 364.945 111.091L363.144 126.956C362.983 128.358 362.329 129.632 361.326 130.498C360.323 131.365 359.052 131.754 357.793 131.579Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_25"
                    d="M377.321 134.313C376.062 134.136 374.917 133.408 374.139 132.292C373.362 131.176 373.014 129.761 373.171 128.359L374.973 112.493C375.134 111.091 375.787 109.819 376.791 108.953C377.794 108.087 379.065 107.7 380.323 107.876C381.582 108.053 382.726 108.778 383.504 109.894C384.282 111.009 384.631 112.422 384.474 113.824L382.671 129.69C382.51 131.092 381.857 132.366 380.855 133.233C379.851 134.1 378.581 134.487 377.321 134.313Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_26"
                    d="M389.987 136.091C388.729 135.914 387.584 135.186 386.807 134.071C386.029 132.954 385.68 131.539 385.838 130.137L387.641 114.272C387.8 112.87 388.454 111.597 389.457 110.731C390.461 109.866 391.731 109.478 392.989 109.654C394.249 109.832 395.392 110.557 396.172 111.672C396.95 112.787 397.298 114.201 397.14 115.603L395.339 131.468C395.178 132.87 394.524 134.144 393.521 135.011C392.518 135.877 391.247 136.266 389.987 136.091Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_27"
                    d="M402.654 137.865C401.395 137.687 400.25 136.96 399.473 135.845C398.696 134.728 398.347 133.313 398.505 131.911L400.306 116.046C400.467 114.644 401.121 113.371 402.124 112.505C403.127 111.64 404.398 111.252 405.656 111.428C406.915 111.604 408.059 112.331 408.837 113.446C409.615 114.561 409.964 115.975 409.807 117.377L408.004 133.242C407.843 134.644 407.191 135.918 406.188 136.785C405.184 137.651 403.914 138.04 402.654 137.865Z"
                    fill="#9C6BFE"
                  />
                  <path
                    id="Vector_28"
                    d="M268.255 178.828L199.939 169.254C199.52 169.196 199.115 169.047 198.747 168.814C198.379 168.582 198.057 168.271 197.798 167.899C197.538 167.527 197.347 167.103 197.236 166.649C197.123 166.195 197.094 165.721 197.146 165.253C197.2 164.786 197.335 164.335 197.545 163.926C197.754 163.517 198.034 163.158 198.369 162.87C198.703 162.581 199.085 162.37 199.493 162.246C199.9 162.121 200.327 162.089 200.746 162.148L269.063 171.721C269.908 171.841 270.676 172.329 271.198 173.079C271.72 173.829 271.953 174.78 271.846 175.721C271.739 176.663 271.301 177.518 270.627 178.1C269.954 178.683 269.102 178.945 268.255 178.828Z"
                    fill="#45125F"
                  />
                  <path
                    id="Vector_29"
                    d="M248.087 160.481L223.577 157.046C222.731 156.928 221.962 156.44 221.438 155.689C220.916 154.938 220.682 153.988 220.789 153.045C220.897 152.103 221.335 151.246 222.009 150.663C222.684 150.081 223.538 149.82 224.384 149.939L248.895 153.373C249.742 153.492 250.511 153.981 251.033 154.73C251.556 155.48 251.79 156.431 251.682 157.375C251.576 158.317 251.137 159.174 250.462 159.756C249.788 160.339 248.934 160.599 248.087 160.481Z"
                    fill="#45125F"
                  />
                  <path
                    id="Vector_30"
                    d="M456.901 80.3946L196.704 43.9342L196.571 45.1094L456.768 81.5696L456.901 80.3946Z"
                    fill="#3F3D56"
                  />
                </g>
                <path
                  id="Vector_31"
                  opacity="0.15"
                  d="M153.418 158.942L153.022 157.473L176.846 149.495L184.609 129.244L177.331 120.811L178.325 119.748L186.234 128.912L177.86 150.758L153.418 158.942Z"
                  fill="black"
                />
                <path
                  id="Vector_32"
                  opacity="0.1"
                  d="M131.791 153.044L125.497 155.429L113.379 155.162C111.288 156.834 109.036 158.24 106.666 159.355L96.2237 157.499L78.583 154.4L144.913 105.948L156.609 110.94C156.609 110.94 131.082 154.292 131.791 153.044Z"
                  fill="black"
                />
                <path
                  id="Vector_33"
                  d="M217.506 146.575C215.895 146.691 214.141 146.728 212.212 146.676C202.258 146.392 181.313 144.935 179.469 144.781C167.088 143.702 159.35 142.609 159.35 142.609L131.792 153.044L125.497 155.429L76.791 154.361L148.889 101.696L156.529 104.955L169.457 110.478L183.7 116.562L196.383 118.989L212.796 122.126L226.061 124.662C228.362 125.075 230.422 126.485 231.792 128.585C233.161 130.684 233.73 133.302 233.371 135.867C232.604 140.737 228.938 145.724 217.506 146.575Z"
                  fill="#FFB7B7"
                />
                <path
                  id="Vector_34"
                  opacity="0.15"
                  d="M222.988 143.56C222.784 143.458 217.97 141 216.938 135.865C216.243 132.412 217.435 128.654 220.479 124.693L221.526 125.689C218.783 129.259 217.691 132.567 218.281 135.52C219.156 139.902 223.51 142.138 223.553 142.161L222.988 143.56Z"
                  fill="black"
                />
                <path
                  id="Vector_35"
                  opacity="0.15"
                  d="M157.217 142.609L132.834 151.845L133.277 153.298L157.659 144.061L157.217 142.609Z"
                  fill="black"
                />
                <path
                  id="Vector_36"
                  opacity="0.15"
                  d="M152.651 106.09L186.808 96.3627L187.148 97.8498L157.632 106.256L180.961 114.574L180.54 116.034L152.651 106.09Z"
                  fill="black"
                />
              </g>
              <path
                id="thumb"
                d="M464.798 96.8412C466.409 96.9391 468.165 96.9547 470.092 96.8781C480.042 96.4765 500.973 94.7666 502.815 94.5907C515.184 93.3618 522.91 92.177 522.91 92.177L550.569 102.28L556.886 104.589L605.578 102.936L532.973 51.1409L525.366 54.4919L512.491 60.1705L498.309 66.4252L485.651 69.0037L469.27 72.3383L456.03 75.0343C453.733 75.4742 451.687 76.9088 450.338 79.0245C448.989 81.1402 448.447 83.7653 448.83 86.3251C449.643 91.1865 453.359 96.1288 464.798 96.8412Z"
                fill="#FFB7B7"
              />
            </g>
          </svg>
        ),
      }}
    >
      <BackButton to="/" />
      <h1 className="page-title">Pay Amount.</h1>
      <p>Fashionova requested a payment.</p>
      <Input label="Amount" value="10000" disabled={true} fullWidth={true} />
      <div className="payment-summary">
        <div className="summary-item">
          <p className="summary-label">INVOICE NUMBER</p>
          <p className="summary-value">01</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">Sender</p>
          <p className="summary-value">admin@gmail.com</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">RECEIVER</p>
          <p className="summary-value">jong@gmail.com</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">REQUESTED</p>
          <p className="summary-value">August 20, 2022</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">AMOUNT</p>
          <p className="summary-value">10000</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">CODE</p>
          <p className="summary-value">25141</p>
        </div>
        <div className="summary-item">
          <p className="summary-label">OFFLINE REFERENCE</p>
          <p className="summary-value">80660011925398</p>
        </div>
      </div>
      <PrimaryButton text="Pay" onClick={() => console.log("Pay")} />
    </Layout>
  );
};

export default Summary;
