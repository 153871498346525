export const ICONS = {
  back: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M9.125 21.1.7 12.7q-.15-.15-.212-.325Q.425 12.2.425 12t.063-.375Q.55 11.45.7 11.3l8.425-8.425q.35-.35.875-.35t.9.375q.375.375.375.875t-.375.875L3.55 12l7.35 7.35q.35.35.35.862 0 .513-.375.888t-.875.375q-.5 0-.875-.375Z" />
    </svg>
  ),
  contentCopy: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M9 18q-.825 0-1.412-.587Q7 16.825 7 16V4q0-.825.588-1.413Q8.175 2 9 2h9q.825 0 1.413.587Q20 3.175 20 4v12q0 .825-.587 1.413Q18.825 18 18 18Zm0-2h9V4H9v12Zm-4 6q-.825 0-1.413-.587Q3 20.825 3 20V7q0-.425.288-.713Q3.575 6 4 6t.713.287Q5 6.575 5 7v13h10q.425 0 .713.288.287.287.287.712t-.287.712Q15.425 22 15 22ZM9 4v12V4Z" />
    </svg>
  ),
  copied: (
    <svg xmlns="http://www.w3.org/2000/svg" height="24" width="24">
      <path d="M5 21q-.825 0-1.413-.587Q3 19.825 3 19V5q0-.825.587-1.413Q4.175 3 5 3h4.175q.275-.875 1.075-1.438Q11.05 1 12 1q1 0 1.788.562.787.563 1.062 1.438H19q.825 0 1.413.587Q21 4.175 21 5v14q0 .825-.587 1.413Q19.825 21 19 21Zm0-2h14V5h-2v3H7V5H5v14Zm7-14q.425 0 .713-.288Q13 4.425 13 4t-.287-.713Q12.425 3 12 3t-.712.287Q11 3.575 11 4t.288.712Q11.575 5 12 5Z" />
    </svg>
  ),
  creditCard: (
    <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48">
      <path d="M42 12.75v22.5q0 1.15-.8 1.95t-1.95.8H8.75q-1.15 0-1.95-.8T6 35.25v-22.5q0-1.15.8-1.95t1.95-.8h30.5q1.15 0 1.95.8t.8 1.95Zm-34.45 5.1h32.9v-5.1q0-.45-.375-.825-.375-.375-.825-.375H8.75q-.45 0-.825.375-.375.375-.375.825Zm0 4v13.4q0 .45.375.825.375.375.825.375h30.5q.45 0 .825-.375.375-.375.375-.825v-13.4Zm0 14.6V11.55 36.45Z" />
    </svg>
  ),
  error: (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="error">
        <mask
          id="mask0_333_1743"
          style={{ maskType: "alpha" }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect id="Bounding box" width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_333_1743)">
          <path
            id="error_2"
            d="M12 13C12.2833 13 12.521 12.904 12.713 12.712C12.9043 12.5207 13 12.2833 13 12V7.975C13 7.69167 12.9043 7.45833 12.713 7.275C12.521 7.09167 12.2833 7 12 7C11.7167 7 11.4793 7.09567 11.288 7.287C11.096 7.479 11 7.71667 11 8V12.025C11 12.3083 11.096 12.5417 11.288 12.725C11.4793 12.9083 11.7167 13 12 13ZM12 17C12.2833 17 12.521 16.904 12.713 16.712C12.9043 16.5207 13 16.2833 13 16C13 15.7167 12.9043 15.479 12.713 15.287C12.521 15.0957 12.2833 15 12 15C11.7167 15 11.4793 15.0957 11.288 15.287C11.096 15.479 11 15.7167 11 16C11 16.2833 11.096 16.5207 11.288 16.712C11.4793 16.904 11.7167 17 12 17ZM12 22C10.6167 22 9.31667 21.7373 8.1 21.212C6.88333 20.6873 5.825 19.975 4.925 19.075C4.025 18.175 3.31267 17.1167 2.788 15.9C2.26267 14.6833 2 13.3833 2 12C2 10.6167 2.26267 9.31667 2.788 8.1C3.31267 6.88333 4.025 5.825 4.925 4.925C5.825 4.025 6.88333 3.31233 8.1 2.787C9.31667 2.26233 10.6167 2 12 2C13.3833 2 14.6833 2.26233 15.9 2.787C17.1167 3.31233 18.175 4.025 19.075 4.925C19.975 5.825 20.6873 6.88333 21.212 8.1C21.7373 9.31667 22 10.6167 22 12C22 13.3833 21.7373 14.6833 21.212 15.9C20.6873 17.1167 19.975 18.175 19.075 19.075C18.175 19.975 17.1167 20.6873 15.9 21.212C14.6833 21.7373 13.3833 22 12 22ZM12 20C14.2167 20 16.1043 19.221 17.663 17.663C19.221 16.1043 20 14.2167 20 12C20 9.78333 19.221 7.89567 17.663 6.337C16.1043 4.779 14.2167 4 12 4C9.78333 4 7.896 4.779 6.338 6.337C4.77933 7.89567 4 9.78333 4 12C4 14.2167 4.77933 16.1043 6.338 17.663C7.896 19.221 9.78333 20 12 20Z"
            fill="#FF0000"
          />
        </g>
      </g>
    </svg>
  ),
  close: (
    <svg xmlns="http://www.w3.org/2000/svg" height="40" width="40">
      <path d="M20 21.958 11.458 30.5q-.416.417-.979.417-.562 0-.979-.417-.417-.417-.417-.979 0-.563.417-.979L18.042 20 9.5 11.458q-.417-.416-.417-.979 0-.562.417-.979.417-.417.979-.417.563 0 .979.417L20 18.042 28.542 9.5q.416-.417.979-.417.562 0 .979.417.417.417.417.979 0 .563-.417.979L21.958 20l8.542 8.542q.417.416.417.979 0 .562-.417.979-.417.417-.979.417-.563 0-.979-.417Z" />
    </svg>
  ),
  moov: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="moov-money-ico-48*48-black" style={{ mixblendmode: "darken" }}>
        <g id="Frame 113">
          <g id="Clip path group">
            <mask
              id="mask0_7204_3557"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="1"
              y="6"
              width="62"
              height="52"
            >
              <g id="clip0_7186_5080">
                <path
                  id="Vector"
                  d="M62.1174 6.66678H1.33203V57.1868H62.1174V6.66678Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask0_7204_3557)">
              <g id="Moov-money-logo">
                <g id="Layer 1">
                  <path
                    id="Vector_2"
                    d="M62.1107 33.8078C62.131 35.0236 61.6761 35.9886 60.7706 36.743C58.2738 38.8218 55.7757 40.8998 53.2483 42.941C51.5091 44.346 49.7337 45.7064 47.9494 47.0528C46.5021 48.1454 45.0255 49.1994 43.5478 50.2508C42.2165 51.1974 40.8733 52.1277 39.5217 53.0458C38.3713 53.8276 37.2057 54.5872 36.0394 55.346C35.3339 55.8048 34.6213 56.254 33.8986 56.6861C33.2793 57.0561 32.6017 57.2337 31.9081 57.176C30.7862 57.2432 29.8598 56.7928 28.9893 56.2192C27.467 55.216 25.948 54.2064 24.4543 53.1613C22.7792 51.9894 21.1225 50.7889 19.4771 49.5744C17.823 48.3536 16.1789 47.1176 14.5551 45.8561C12.906 44.5756 11.269 43.278 9.65918 41.9481C7.5126 40.174 5.39584 38.363 3.26828 36.5654C2.59252 35.995 1.99797 35.3504 1.68198 34.5102C1.51256 34.0578 1.28794 33.5609 1.33172 33.108C1.40977 32.3053 1.77081 31.5724 2.40089 31.0133C4.29684 29.3332 6.1966 27.658 8.08621 25.9708C10.8026 23.5457 13.5209 21.123 16.2195 18.6776C19.3331 15.8572 22.4258 13.0126 25.5286 10.1795C26.4404 9.34697 27.3262 8.48212 28.2774 7.69659C29.46 6.71941 30.8681 6.49099 32.337 6.78731C33.273 6.97576 34.0419 7.53921 34.7431 8.18199C36.5331 9.82287 38.318 11.4688 40.1182 13.0976C42.5375 15.2854 44.9577 17.4714 47.3987 19.6352C50.5655 22.4429 53.749 25.2316 56.933 28.019C58.2909 29.2076 59.6729 30.3686 61.0346 31.5526C61.7154 32.1441 62.0949 32.863 62.1107 33.8078Z"
                    fill="black"
                  />
                  <g id="Group">
                    <path
                      id="Vector_2_2"
                      d="M20.1119 28.9469C19.9976 29.8118 19.8994 30.6785 19.7731 31.5416C19.5788 32.8728 19.3739 34.2026 19.1588 35.5308C19.0211 36.3804 18.8555 37.2256 18.7046 38.0726C18.6582 38.3302 18.5319 38.442 18.2495 38.4254C17.7971 38.3988 17.3422 38.419 16.8879 38.4172C16.5091 38.4153 16.4494 38.3417 16.5236 37.9578C16.7368 36.8544 16.9519 35.7516 17.1676 34.6488C17.2991 33.9788 17.4355 33.3093 17.5618 32.638C17.5782 32.5504 17.5459 32.4533 17.5358 32.3601C17.5116 32.3569 17.4868 32.353 17.4627 32.3493C17.287 32.6417 17.1156 32.9368 16.9348 33.2256C16.1855 34.419 15.433 35.6106 14.6816 36.8029C14.5071 37.0802 14.2743 37.2477 13.9284 37.2357C13.686 37.2268 13.4431 37.249 13.2006 37.2472C12.839 37.2446 12.6943 37.1202 12.6404 36.7808C12.4544 35.6088 12.2673 34.4368 12.0845 33.2649C12.0376 32.9634 12.0058 32.6596 11.9671 32.3569C11.9246 32.3537 11.8821 32.3498 11.8396 32.3468C11.7495 32.5745 11.6385 32.7966 11.5737 33.0313C11.1607 34.5288 10.7799 36.0352 10.3434 37.5264C10.049 38.5326 10.3066 38.4204 9.18539 38.4185C8.85798 38.4178 8.53056 38.4216 8.20379 38.4185C8.00391 38.416 7.91824 38.3442 7.98551 38.1189C8.42079 36.6729 8.82244 35.2166 9.27994 33.7776C9.78184 32.1989 10.3332 30.6354 10.8586 29.0637C10.9544 28.7769 11.1188 28.6334 11.4373 28.6481C11.8694 28.669 12.3034 28.6684 12.7362 28.6532C12.969 28.6456 13.0915 28.7286 13.1315 28.9558C13.3872 30.4234 13.6455 31.8912 13.9038 33.3588C13.9475 33.6076 13.9907 33.8562 14.0484 34.1018C14.0655 34.1754 14.1322 34.2369 14.1766 34.3042C14.2508 34.2548 14.3498 34.2224 14.3955 34.1538C14.9792 33.2788 15.549 32.3937 16.1359 31.5212C16.7058 30.6741 17.2934 29.8397 17.8688 28.9965C18.0307 28.7592 18.2299 28.6405 18.53 28.6544C18.9723 28.6748 19.4164 28.6658 19.86 28.6576C20.0763 28.6532 20.1386 28.747 20.1119 28.9469Z"
                      fill="white"
                    />
                    <path
                      id="Vector_3"
                      d="M48.5224 31.3836C48.1043 32.3798 47.7026 33.3856 47.2299 34.3557C46.2775 36.3101 45.1347 38.1514 43.6918 39.7846C42.7083 40.8977 41.4291 41.2225 39.9887 40.9541C39.8371 40.9256 39.6911 40.8532 39.5496 40.7866C39.3758 40.7041 39.3422 40.5658 39.4183 40.3913C39.5782 40.0258 39.74 39.6616 39.8891 39.2917C39.9576 39.121 40.0623 39.0709 40.2343 39.1165C40.3967 39.159 40.5611 39.1946 40.7254 39.2264C41.4227 39.3596 42.2824 38.9338 42.563 38.2866C42.6327 38.1261 42.6359 37.9122 42.6023 37.7358C42.4183 36.758 42.169 35.7904 42.0262 34.8069C41.8606 33.6648 41.7838 32.5098 41.6644 31.3608C41.6366 31.0912 41.7647 31.0036 42.014 31.0061C42.5103 31.0124 43.0064 31.0144 43.5027 31.0036C43.7247 30.999 43.8003 31.1025 43.8148 31.3044C43.898 32.4077 43.9799 33.5112 44.0756 34.614C44.1055 34.9541 44.1791 35.2904 44.2324 35.6286C44.261 35.64 44.2902 35.6514 44.3187 35.6629C44.4094 35.5606 44.5198 35.4706 44.5864 35.355C45.3022 34.1209 45.8282 32.8056 46.2228 31.4388C46.3148 31.1184 46.4767 30.9902 46.806 31.0029C47.2908 31.0213 47.7775 31.0073 48.2635 31.008C48.5922 31.0093 48.6524 31.0728 48.5224 31.3836Z"
                      fill="white"
                    />
                    <path
                      id="Vector_4"
                      d="M28.1675 29.5732C28.1695 29.8321 28.0909 29.9425 27.825 29.9285C27.5515 29.9146 27.2761 29.9273 27.0014 29.9253C26.8097 29.9241 26.7082 29.8302 26.7101 29.6329C26.7241 28.1449 26.7381 26.6576 26.7501 25.1696C26.7514 25.0402 26.7393 24.9114 26.7335 24.7819C26.6999 24.7774 26.6657 24.773 26.6321 24.7693C26.5445 25.1201 26.4525 25.4704 26.3706 25.8232C26.1753 26.6709 25.983 27.5193 25.7933 28.3689C25.7069 28.7578 25.6225 29.1481 25.5527 29.5402C25.5033 29.8149 25.3669 29.9432 25.0794 29.9266C24.8269 29.9121 24.5723 29.919 24.3193 29.9292C24.0679 29.9393 23.9163 29.8263 23.8623 29.5878C23.7418 29.0561 23.6289 28.523 23.5102 27.9908C23.3186 27.1309 23.1219 26.2712 22.9322 25.4108C22.8814 25.1817 22.8485 24.9487 22.8071 24.7178C22.771 24.7191 22.7342 24.7197 22.6974 24.7203C22.6809 24.8783 22.651 25.0357 22.6505 25.1932C22.6473 26.6392 22.6409 28.0853 22.6555 29.5313C22.6587 29.8397 22.561 29.954 22.257 29.9292C22.0261 29.9108 21.7926 29.9253 21.5603 29.9273C21.3598 29.9285 21.2475 29.8556 21.2475 29.6304C21.2487 27.466 21.2443 25.3022 21.2393 23.1385C21.2386 22.9329 21.3154 22.8174 21.5318 22.8174C22.0907 22.8174 22.651 22.7983 23.2082 22.8301C23.3223 22.8371 23.4886 23.0161 23.5229 23.1449C23.6942 23.7825 23.8326 24.4291 23.9753 25.0745C24.1714 25.9653 24.3605 26.8581 24.5559 27.7495C24.5705 27.8169 24.6111 27.879 24.6726 28.0186C24.7406 27.8588 24.797 27.7648 24.8211 27.6633C25.113 26.4393 25.4005 25.2146 25.6873 23.9894C25.757 23.6937 25.8046 23.391 25.8966 23.103C25.9315 22.9945 26.0617 22.8428 26.153 22.839C26.7627 22.8162 27.3751 22.8174 27.9849 22.8428C28.0495 22.8454 28.1543 23.0592 28.1562 23.176C28.1707 24.0732 28.1638 24.9704 28.1638 25.8676C28.1631 27.103 28.1574 28.3384 28.1675 29.5732Z"
                      fill="white"
                    />
                    <path
                      id="Vector_5"
                      d="M27.2063 33.391C27.1212 32.412 26.7537 31.6049 25.8293 31.1346C24.4777 30.4474 22.7201 30.7153 21.6033 31.8492C20.1592 33.3156 19.7068 35.0592 20.3287 37.0396C20.4676 37.4798 20.7169 37.8726 21.1408 38.1277C22.5564 38.9798 24.3236 38.834 25.5957 37.7178C26.7537 36.7013 27.2595 35.3954 27.2081 33.898C27.2081 33.707 27.2195 33.5477 27.2063 33.391ZM24.3769 36.3136C23.9791 36.799 23.4568 36.9184 22.8724 36.8213C22.5888 36.7737 22.4352 36.5573 22.3312 36.2965C22.0235 35.5217 22.1332 34.7553 22.4467 34.0288C22.6243 33.6162 22.8952 33.219 23.2068 32.8948C23.5552 32.5318 24.0559 32.4354 24.5247 32.6366C24.9949 32.839 25.0907 33.3053 25.1205 33.7737C25.1256 33.8574 25.1212 33.9424 25.1212 33.9602C25.1371 34.8745 24.9232 35.6468 24.3769 36.3136Z"
                      fill="white"
                    />
                    <path
                      id="Vector_6"
                      d="M34.2569 33.7478C34.0862 34.8005 33.8063 35.8361 33.5659 36.8779C33.4669 37.3069 33.3514 37.7319 33.2486 38.1602C33.2005 38.3601 33.0767 38.4293 32.8799 38.4223C32.6269 38.4127 32.373 38.4198 32.1198 38.4198C31.8666 38.4198 31.6332 38.4109 31.3908 38.4217C31.1534 38.4325 31.0614 38.3563 31.1217 38.1057C31.2969 37.3722 31.4593 36.6362 31.6255 35.9007C31.7962 35.1443 31.9758 34.3886 32.1319 33.6291C32.2334 33.133 31.9022 32.6958 31.4015 32.6462C31.1934 32.6259 30.9809 32.6381 30.7709 32.6526C30.5189 32.6698 30.373 32.8221 30.3165 33.0638C29.9713 34.5537 29.6313 36.0454 29.2803 37.5339C29.0607 38.4642 29.2201 38.4573 28.1325 38.423C27.8578 38.4141 27.583 38.4191 27.3089 38.4191C26.9605 38.4185 26.8933 38.3469 26.9719 38.0086C27.0945 37.4769 27.2334 36.9483 27.3622 36.4178C27.7371 34.8683 28.1267 33.3221 28.4758 31.7662C28.5899 31.2547 28.9853 31.1774 29.3565 31.0866C30.2277 30.8753 31.1154 30.7503 32.0164 30.8207C32.9834 30.8957 33.8095 31.2541 34.1414 32.2255C34.3013 32.6951 34.3362 33.2561 34.2569 33.7478Z"
                      fill="white"
                    />
                    <path
                      id="Vector_7"
                      d="M40.2358 31.0916C39.291 30.591 38.3239 30.669 37.3759 31.0865C35.4985 31.9126 34.5771 33.3861 34.5086 35.4121C34.5086 35.6025 34.4991 35.7928 34.5105 35.9818C34.5835 37.2217 35.2313 38.1082 36.3614 38.4641C37.5099 38.8265 38.6514 38.6596 39.7745 38.3277C39.8817 38.296 40.0074 38.0949 40.0061 37.9737C40.001 37.6292 39.9306 37.2865 39.8945 36.942C39.8557 36.5765 39.7809 36.5181 39.4325 36.6418C38.8646 36.8442 38.277 36.8728 37.6869 36.8309C37.3418 36.8061 37.0219 36.6685 36.8227 36.3709C36.737 36.2433 36.6889 36.058 36.7066 35.9082C36.7135 35.8518 36.9515 35.7953 37.0873 35.7865C37.8329 35.7382 38.5803 35.7192 39.2917 35.4457C40.0658 35.1488 40.7459 34.763 41.1107 33.9482C41.6146 32.8226 41.2846 31.6474 40.2358 31.0916ZM38.909 33.9089C38.4375 34.0726 37.9465 34.1805 37.4603 34.3004C37.2986 34.3404 37.1266 34.3436 36.9598 34.3632C36.7338 34.3905 36.6215 34.2794 36.6838 34.0688C36.9573 33.1469 37.7409 32.2649 38.9725 32.5326C39.2485 32.5922 39.3874 32.8105 39.3874 33.244C39.3791 33.506 39.2339 33.7966 38.909 33.9089Z"
                      fill="white"
                    />
                    <path
                      id="Vector_8"
                      d="M34.4796 27.1068C34.4142 25.587 33.4122 24.4285 31.8145 24.457C30.0106 24.4361 28.963 25.7273 29.0456 27.5426C29.1185 29.1398 30.2174 30.1525 31.883 30.1328C33.675 30.1125 34.5488 28.7312 34.4796 27.1068ZM32.6318 28.5978C32.029 29.2666 30.8812 28.9977 30.6109 28.1112C30.4249 27.4989 30.4274 26.8682 30.6978 26.2597C30.9085 25.7857 31.3018 25.6404 31.7726 25.6436C32.2098 25.6468 32.5994 25.7876 32.7696 26.2273C32.9002 26.5642 32.97 26.9246 33.0672 27.2742C33.0062 27.7641 32.9586 28.2356 32.6318 28.5978Z"
                      fill="white"
                    />
                    <path
                      id="Vector_9"
                      d="M40.4558 26.659C40.2712 25.6197 39.6932 24.8709 38.664 24.5866C37.6562 24.3074 36.6728 24.3728 35.8777 25.1749C35.2813 25.777 35.0826 26.522 35.0624 27.3589C35.0306 28.6197 35.9108 30.2232 37.4685 30.1418C38.641 30.1114 39.4069 29.8918 39.9342 29.1521C40.4722 28.3982 40.615 27.5537 40.4558 26.659ZM38.6017 28.6572C37.9926 29.2206 36.9501 28.9833 36.6702 28.2078C36.4564 27.6146 36.457 26.9966 36.6702 26.4097C36.8657 25.869 37.1778 25.6552 37.8112 25.6476C38.3429 25.6406 38.7134 25.8957 38.8822 26.4045C38.9773 26.6901 39.0382 26.9877 39.0973 27.2136C39.0053 27.7784 39.0008 28.2885 38.6017 28.6572Z"
                      fill="white"
                    />
                    <path
                      id="Vector_10"
                      d="M55.1243 36.218C54.4746 36.4864 53.8325 36.7808 53.1623 36.9857C52.4321 37.2092 51.6821 37.3994 50.9263 37.5016C49.8629 37.6457 48.7874 37.7002 47.7163 37.7833C47.2955 37.8164 46.8737 37.8284 46.4523 37.8392C46.3426 37.8417 46.2327 37.8042 46.123 37.7853C46.1217 37.7592 46.1205 37.7332 46.1191 37.7072C46.347 37.6729 46.5747 37.6418 46.8019 37.6038C47.8171 37.4344 48.8431 37.3118 49.8445 37.0816C51.2538 36.758 52.663 36.4116 53.9739 35.7662C54.4213 35.546 54.4511 35.4457 54.3229 34.982C54.0411 33.9616 53.7861 32.9344 53.5247 31.9089C53.4657 31.6761 53.4263 31.4374 53.3769 31.2021C53.3058 30.862 53.2214 30.8372 52.9461 31.039C52.4447 31.4052 51.9695 31.8252 51.4219 32.1037C50.5177 32.5644 49.5653 32.9324 48.6299 33.3322C48.5342 33.3734 48.4149 33.3608 48.2987 33.3741C48.3311 32.8658 48.5761 32.5352 48.991 32.3874C50.5457 31.8328 51.9567 31.0402 53.1542 29.8898C53.2677 29.7801 53.3921 29.6812 53.5183 29.586C53.6637 29.4756 53.7773 29.5124 53.8267 29.6874C54.1885 30.9686 54.5514 32.249 54.9054 33.5321C55.0926 34.209 55.2481 34.8944 55.4461 35.5676C55.5469 35.9109 55.4073 36.1012 55.1243 36.218Z"
                      fill="white"
                    />
                    <path
                      id="Vector_11"
                      d="M46.0951 24.9666C45.7105 26.0314 45.326 27.0954 44.9408 28.1589C44.7835 28.5935 44.6109 29.0231 44.47 29.4629C44.3615 29.7998 44.18 29.9838 43.8044 29.9311C43.6699 29.9114 43.5303 29.9279 43.3932 29.9279C42.7791 29.9267 42.7695 29.9305 42.5581 29.3479C42.0328 27.8987 41.5163 26.4463 41.0004 24.9939C40.97 24.9095 40.9757 24.8131 40.958 24.6697C41.4364 24.6697 41.8596 24.643 42.2759 24.6862C42.4003 24.6989 42.5652 24.8879 42.6077 25.0282C42.8589 25.8619 43.0747 26.7058 43.3145 27.5434C43.3716 27.7426 43.4751 27.9279 43.5576 28.1195C43.5963 28.1133 43.6349 28.1062 43.6743 28.0999C43.7188 27.9787 43.7733 27.8607 43.8069 27.737C44.0468 26.8575 44.286 25.9781 44.5176 25.0974C44.6027 24.7725 44.7803 24.6221 45.1319 24.6633C45.3819 24.6925 45.6381 24.6761 45.8913 24.6678C46.1268 24.6602 46.1648 24.7743 46.0951 24.9666Z"
                      fill="white"
                    />
                    <path
                      id="Vector_12"
                      d="M54.8457 37.8283C52.7569 38.5103 50.6204 38.7743 48.4313 38.5491C47.8704 38.4914 47.3145 38.3866 46.7567 38.3029C46.7562 38.2775 46.7555 38.2522 46.7549 38.2267C46.8513 38.2077 46.9472 38.1709 47.043 38.1729C47.5594 38.1811 48.0778 38.235 48.5918 38.2046C49.3888 38.157 50.1877 38.0903 50.9757 37.9673C52.2029 37.7743 53.3786 37.3917 54.5138 36.8866C54.8108 36.7546 54.9301 36.8339 55.0704 37.2235C55.1884 37.551 55.1147 37.7401 54.8457 37.8283Z"
                      fill="white"
                    />
                    <path
                      id="Vector_13"
                      d="M55.0186 38.4851C53.2235 38.9839 51.2578 39.0899 49.4075 38.9794C48.9715 38.9534 48.537 38.9122 48.1035 38.8563C48.6486 39.0714 49.2273 39.1901 49.8073 39.2707C51.4691 39.503 53.1874 39.4681 54.8511 39.2657C55.1385 39.2307 55.2546 39.1533 55.2502 38.857C55.2483 38.7333 55.2102 38.4325 55.0186 38.4851Z"
                      fill="white"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  orange: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="orange-money-ico-48*48-black">
        <g id="Frame 110">
          <g id="Clip path group">
            <mask
              id="mask0_7204_3555"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="10"
              y="6"
              width="44"
              height="52"
            >
              <g id="clip0_7186_5018">
                <path
                  id="Vector"
                  d="M53.4113 6.66669H10.666V57.3334H53.4113V6.66669Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask0_7204_3555)">
              <g id="orange-money-shit 1">
                <path
                  id="Vector_2"
                  d="M10.6816 45.6387H13.0541L15.5597 51.7519H15.6664L18.1721 45.6387H20.5445V54.7375H18.6787V48.8154H18.6031L16.2484 54.6931H14.9777L12.6231 48.7931H12.5476V54.7375H10.6816V45.6387Z"
                  fill="black"
                />
                <path
                  id="Vector_2_2"
                  d="M24.8061 54.8705C24.1159 54.8705 23.5191 54.7239 23.0157 54.4307C22.515 54.1344 22.1286 53.7228 21.8561 53.1955C21.5835 52.6653 21.4473 52.0508 21.4473 51.3518C21.4473 50.647 21.5835 50.0308 21.8561 49.5036C22.1286 48.9735 22.515 48.5616 23.0157 48.2684C23.5191 47.9724 24.1159 47.8243 24.8061 47.8243C25.4962 47.8243 26.0915 47.9724 26.5921 48.2684C27.0957 48.5616 27.4835 48.9735 27.7561 49.5036C28.0285 50.0308 28.1649 50.647 28.1649 51.3518C28.1649 52.0508 28.0285 52.6653 27.7561 53.1955C27.4835 53.7228 27.0957 54.1344 26.5921 54.4307C26.0915 54.7239 25.4962 54.8705 24.8061 54.8705ZM24.8149 53.4043C25.1289 53.4043 25.391 53.3155 25.6013 53.1377C25.8115 52.9571 25.9701 52.7112 26.0767 52.4003C26.1863 52.0894 26.241 51.7352 26.241 51.3384C26.241 50.9415 26.1863 50.5876 26.0767 50.2766C25.9701 49.9658 25.8115 49.7199 25.6013 49.5391C25.391 49.3584 25.1289 49.2681 24.8149 49.2681C24.4981 49.2681 24.2314 49.3584 24.0153 49.5391C23.8019 49.7199 23.6406 49.9658 23.531 50.2766C23.4243 50.5876 23.371 50.9415 23.371 51.3384C23.371 51.7352 23.4243 52.0894 23.531 52.4003C23.6406 52.7112 23.8019 52.9571 24.0153 53.1377C24.2314 53.3155 24.4981 53.4043 24.8149 53.4043Z"
                  fill="black"
                />
                <path
                  id="Vector_3"
                  d="M30.9132 50.792V54.7372H29.0205V47.9129H30.8244V49.1171H30.9044C31.0554 48.7203 31.3086 48.4063 31.664 48.1752C32.0194 47.9412 32.4505 47.8243 32.9569 47.8243C33.4308 47.8243 33.8438 47.928 34.1965 48.1352C34.5489 48.3425 34.8229 48.6388 35.0184 49.0237C35.2138 49.4057 35.3116 49.862 35.3116 50.3922V54.7372H33.419V50.7297C33.422 50.3121 33.3153 49.9863 33.099 49.7524C32.8829 49.5153 32.5852 49.3969 32.2061 49.3969C31.9513 49.3969 31.7262 49.4518 31.5308 49.5614C31.3382 49.671 31.1873 49.8308 31.0776 50.0412C30.971 50.2484 30.9161 50.4988 30.9132 50.792Z"
                  fill="black"
                />
                <path
                  id="Vector_4"
                  d="M39.5357 54.8705C38.8337 54.8705 38.2296 54.7284 37.7231 54.4439C37.2196 54.1567 36.8316 53.7509 36.5592 53.2265C36.2867 52.6995 36.1504 52.0759 36.1504 51.3563C36.1504 50.6543 36.2867 50.0381 36.5592 49.508C36.8316 48.9779 37.2151 48.5647 37.7099 48.2684C38.2073 47.9724 38.7909 47.8243 39.4603 47.8243C39.9105 47.8243 40.3296 47.8967 40.7176 48.0419C41.1085 48.1841 41.4492 48.3988 41.7393 48.6861C42.0327 48.9735 42.2607 49.3348 42.4236 49.7701C42.5864 50.2025 42.6679 50.7091 42.6679 51.2896V51.8093H36.9056V50.6364H40.8864C40.8864 50.364 40.8271 50.1227 40.7085 49.9123C40.5903 49.702 40.4259 49.5376 40.2155 49.4191C40.0083 49.2977 39.7668 49.2371 39.4913 49.2371C39.204 49.2371 38.9493 49.3038 38.7272 49.437C38.508 49.5672 38.3363 49.7435 38.2119 49.9657C38.0873 50.1848 38.0237 50.4292 38.0207 50.6987V51.8139C38.0207 52.1515 38.0831 52.4432 38.2073 52.6889C38.3347 52.9348 38.5139 53.1245 38.7449 53.2576C38.9759 53.3909 39.25 53.4576 39.5668 53.4576C39.7772 53.4576 39.9696 53.428 40.1444 53.3687C40.3192 53.3095 40.4687 53.2208 40.5932 53.1023C40.7176 52.9837 40.8123 52.8385 40.8775 52.6668L42.628 52.7824C42.5391 53.2029 42.3569 53.5702 42.0815 53.8842C41.8091 54.1951 41.4565 54.438 41.0241 54.6128C40.5947 54.7845 40.0985 54.8705 39.5357 54.8705Z"
                  fill="black"
                />
                <path
                  id="Vector_5"
                  d="M44.5016 57.2963C44.2614 57.2963 44.0363 57.2771 43.8261 57.2386C43.6187 57.2031 43.447 57.1573 43.3108 57.1009L43.7373 55.688C43.9594 55.7562 44.1594 55.7933 44.3371 55.7991C44.5178 55.805 44.6733 55.7637 44.8035 55.6747C44.9369 55.5859 45.0449 55.4349 45.128 55.2215L45.2389 54.9329L42.791 47.9131H44.7813L46.1941 52.9247H46.2653L47.6914 47.9131H49.695L47.0428 55.4747C46.9154 55.842 46.7421 56.1619 46.5229 56.4345C46.3066 56.7099 46.0327 56.9217 45.701 57.0698C45.3693 57.2209 44.9694 57.2963 44.5016 57.2963Z"
                  fill="black"
                />
                <path
                  id="Vector_6"
                  d="M19.3136 38.2671C19.3136 39.2722 19.1231 40.1274 18.742 40.8323C18.364 41.5375 17.8479 42.076 17.1939 42.448C16.5428 42.8171 15.8107 43.0016 14.9976 43.0016C14.1785 43.0016 13.4435 42.8155 12.7924 42.4435C12.1413 42.0715 11.6267 41.533 11.2487 40.8279C10.8707 40.1228 10.6816 39.2691 10.6816 38.2671C10.6816 37.262 10.8707 36.4068 11.2487 35.7019C11.6267 34.9967 12.1413 34.4596 12.7924 34.0907C13.4435 33.7187 14.1785 33.5326 14.9976 33.5326C15.8107 33.5326 16.5428 33.7187 17.1939 34.0907C17.8479 34.4596 18.364 34.9967 18.742 35.7019C19.1231 36.4068 19.3136 37.262 19.3136 38.2671ZM17.3379 38.2671C17.3379 37.6159 17.2403 37.067 17.0453 36.6199C16.8533 36.173 16.5817 35.8338 16.2307 35.6027C15.8797 35.3718 15.4687 35.2563 14.9976 35.2563C14.5265 35.2563 14.1155 35.3718 13.7644 35.6027C13.4135 35.8338 13.1404 36.173 12.9454 36.6199C12.7534 37.067 12.6574 37.6159 12.6574 38.2671C12.6574 38.9182 12.7534 39.4671 12.9454 39.9142C13.1404 40.3614 13.4135 40.7003 13.7644 40.9314C14.1155 41.1623 14.5265 41.2779 14.9976 41.2779C15.4687 41.2779 15.8797 41.1623 16.2307 40.9314C16.5817 40.7003 16.8533 40.3614 17.0453 39.9142C17.2403 39.4671 17.3379 38.9182 17.3379 38.2671Z"
                  fill="black"
                />
                <path
                  id="Vector_7"
                  d="M20.3486 42.8752V35.9624H22.2073V37.1686H22.2793C22.4054 36.7396 22.6169 36.4156 22.914 36.1964C23.211 35.9746 23.553 35.8635 23.9401 35.8635C24.0361 35.8635 24.1396 35.8695 24.2506 35.8814C24.3616 35.8935 24.459 35.9099 24.5432 35.931V37.6322C24.453 37.6051 24.3286 37.5811 24.1696 37.5602C24.0105 37.5391 23.865 37.5286 23.733 37.5286C23.451 37.5286 23.1989 37.5902 22.9769 37.7132C22.758 37.8332 22.5838 38.0012 22.4549 38.2171C22.3289 38.4332 22.2658 38.6823 22.2658 38.9643V42.8752H20.3486Z"
                  fill="black"
                />
                <path
                  id="Vector_8"
                  d="M26.9191 43.0057C26.4782 43.0057 26.0851 42.9293 25.74 42.7764C25.3951 42.6204 25.122 42.3908 24.921 42.0877C24.723 41.7817 24.624 41.4007 24.624 40.9447C24.624 40.5605 24.6944 40.238 24.8355 39.9771C24.9764 39.716 25.1686 39.5059 25.4115 39.3469C25.6546 39.1879 25.9306 39.0679 26.2396 38.9868C26.5516 38.9059 26.8787 38.8489 27.2207 38.8159C27.6228 38.7739 27.9468 38.7348 28.1928 38.6988C28.4388 38.6599 28.6175 38.6029 28.7283 38.5279C28.8394 38.4528 28.895 38.3417 28.895 38.1948V38.1679C28.895 37.8828 28.8048 37.6623 28.625 37.5063C28.4478 37.3503 28.1958 37.2723 27.8688 37.2723C27.5239 37.2723 27.2492 37.3488 27.0452 37.5019C26.8412 37.6517 26.7062 37.8408 26.6403 38.0688L24.867 37.9248C24.957 37.5048 25.134 37.1417 25.398 36.8357C25.662 36.5265 26.0027 36.2896 26.4196 36.1245C26.8398 35.9565 27.3258 35.8725 27.8778 35.8725C28.2619 35.8725 28.6294 35.9176 28.9804 36.0075C29.3344 36.0976 29.6479 36.2371 29.921 36.4261C30.1971 36.6151 30.4146 36.8583 30.5735 37.1552C30.7326 37.4492 30.812 37.8017 30.812 38.2128V42.8753H28.9939V41.9167H28.94C28.829 42.1328 28.6803 42.3232 28.4944 42.4883C28.3083 42.6503 28.0848 42.7779 27.8238 42.8708C27.5627 42.9608 27.2612 43.0057 26.9191 43.0057ZM27.4683 41.6827C27.7503 41.6827 27.9992 41.6272 28.2154 41.5161C28.4314 41.4021 28.6008 41.2492 28.7239 41.0572C28.8468 40.8651 28.9084 40.6476 28.9084 40.4047V39.6709C28.8483 39.7099 28.7659 39.746 28.661 39.7789C28.5588 39.8091 28.4434 39.8376 28.3143 39.8644C28.1854 39.8885 28.0564 39.9109 27.9274 39.932C27.7983 39.95 27.6812 39.9665 27.5763 39.9815C27.3512 40.0145 27.1547 40.0669 26.9867 40.1391C26.8187 40.2111 26.6882 40.3085 26.5951 40.4315C26.5022 40.5515 26.4556 40.7016 26.4556 40.8816C26.4556 41.1427 26.5502 41.3421 26.7392 41.4801C26.9312 41.6152 27.1743 41.6827 27.4683 41.6827Z"
                  fill="black"
                />
                <path
                  id="Vector_9"
                  d="M33.8382 38.8789V42.8753H31.9209V35.9625H33.7482V37.1823H33.8292C33.9821 36.7803 34.2388 36.4621 34.5988 36.2281C34.9588 35.9911 35.3954 35.8725 35.9084 35.8725C36.3884 35.8725 36.807 35.9776 37.164 36.1876C37.521 36.3976 37.7985 36.6977 37.9966 37.0876C38.1946 37.4748 38.2937 37.9368 38.2937 38.4739V42.8753H36.3765V38.8159C36.3794 38.3929 36.2714 38.0628 36.0524 37.8259C35.8333 37.5859 35.5318 37.4657 35.1477 37.4657C34.8897 37.4657 34.6617 37.5212 34.4637 37.6323C34.2688 37.7433 34.1157 37.9053 34.0046 38.1183C33.8966 38.3283 33.8412 38.5819 33.8382 38.8789Z"
                  fill="black"
                />
                <path
                  id="Vector_10"
                  d="M42.5418 45.6116C41.9208 45.6116 41.3881 45.5261 40.9441 45.3552C40.503 45.1871 40.1521 44.9576 39.891 44.6664C39.6301 44.3755 39.4605 44.0484 39.3825 43.6853L41.1557 43.4468C41.2097 43.5849 41.2952 43.714 41.4122 43.834C41.5292 43.954 41.6837 44.0499 41.8758 44.122C42.0708 44.1969 42.3077 44.2345 42.5868 44.2345C43.004 44.2345 43.3474 44.1324 43.6174 43.9284C43.8905 43.7273 44.027 43.3899 44.027 42.9159V41.6512H43.946C43.862 41.8432 43.736 42.0248 43.568 42.1957C43.3998 42.3667 43.1838 42.5063 42.9198 42.6143C42.6558 42.7223 42.3408 42.7764 41.9748 42.7764C41.4557 42.7764 40.9832 42.6564 40.5572 42.4163C40.134 42.1733 39.7965 41.8027 39.5445 41.3047C39.2954 40.8036 39.1709 40.1705 39.1709 39.4055C39.1709 38.6223 39.2985 37.9683 39.5536 37.4432C39.8085 36.9181 40.1476 36.5251 40.5706 36.2641C40.9968 36.0031 41.4632 35.8725 41.9702 35.8725C42.3572 35.8725 42.6814 35.9387 42.9424 36.0705C43.2034 36.1996 43.4134 36.3616 43.5725 36.5567C43.7345 36.7487 43.859 36.9377 43.946 37.1237H44.018V35.9625H45.9217V42.9429C45.9217 43.5308 45.7776 44.0229 45.4896 44.4189C45.2016 44.8151 44.8025 45.1121 44.2926 45.31C43.7854 45.5111 43.2018 45.6116 42.5418 45.6116ZM42.5824 41.3361C42.8914 41.3361 43.1524 41.2597 43.3654 41.1067C43.5814 40.9505 43.7465 40.7287 43.8605 40.4407C43.9776 40.1495 44.036 39.8016 44.036 39.3965C44.036 38.9915 43.979 38.6404 43.865 38.3433C43.7509 38.0433 43.586 37.8108 43.37 37.6457C43.1538 37.4808 42.8914 37.3983 42.5824 37.3983C42.2673 37.3983 42.0018 37.4837 41.7857 37.6547C41.5697 37.8227 41.4062 38.0568 41.2952 38.3569C41.1841 38.6568 41.1286 39.0035 41.1286 39.3965C41.1286 39.7955 41.1841 40.1405 41.2952 40.4315C41.4092 40.7195 41.5728 40.9431 41.7857 41.1021C42.0018 41.2581 42.2673 41.3361 42.5824 41.3361Z"
                  fill="black"
                />
                <path
                  id="Vector_11"
                  d="M50.2156 43.0103C49.5045 43.0103 48.8924 42.8664 48.3795 42.5784C47.8693 42.2872 47.4763 41.8761 47.2001 41.3452C46.9243 40.8112 46.7861 40.1795 46.7861 39.4504C46.7861 38.7393 46.9243 38.1153 47.2001 37.5781C47.4763 37.0412 47.8648 36.6227 48.3659 36.3225C48.87 36.0227 49.4611 35.8725 50.1391 35.8725C50.5952 35.8725 51.0196 35.9461 51.4127 36.0932C51.8088 36.2371 52.1537 36.4547 52.4479 36.7457C52.7448 37.0367 52.9759 37.4027 53.1409 37.8437C53.3059 38.2819 53.3884 38.7949 53.3884 39.3829V39.9095H47.5513V38.7215H51.5837C51.5837 38.4453 51.5237 38.2008 51.4037 37.9879C51.2837 37.7747 51.1172 37.6083 50.9041 37.4883C50.6941 37.3652 50.4496 37.3037 50.1705 37.3037C49.8796 37.3037 49.6216 37.3712 49.3965 37.5063C49.1744 37.6383 49.0004 37.8167 48.8744 38.0419C48.7484 38.2637 48.6839 38.5115 48.6809 38.7843V39.914C48.6809 40.256 48.744 40.5515 48.87 40.8005C48.9989 41.0497 49.1805 41.2416 49.4144 41.3767C49.6485 41.5116 49.926 41.5792 50.2471 41.5792C50.46 41.5792 50.6551 41.5492 50.8321 41.4892C51.0092 41.4292 51.1607 41.3392 51.2867 41.2192C51.4127 41.0992 51.5087 40.9521 51.5747 40.7781L53.3479 40.8952C53.2579 41.3211 53.0733 41.6932 52.7943 42.0113C52.5184 42.3263 52.1613 42.5723 51.7233 42.7493C51.2881 42.9233 50.7856 43.0103 50.2156 43.0103Z"
                  fill="black"
                />
                <path
                  id="Vector_12"
                  d="M32.8066 11.3379C32.8066 9.89336 33.9776 8.72235 35.4221 8.72235C36.8666 8.72235 38.0377 9.89336 38.0377 11.3379V23.8924C38.0377 25.337 36.8666 26.508 35.4221 26.508C33.9776 26.508 32.8066 25.337 32.8066 23.8924V11.3379Z"
                  fill="black"
                />
                <path
                  id="Vector_13"
                  d="M47.9766 21.2768C49.4212 21.2768 50.5922 22.4479 50.5922 23.8924C50.5922 25.337 49.4212 26.5079 47.9766 26.5079H35.4221C33.9776 26.5079 32.8066 25.337 32.8066 23.8924C32.8066 22.4479 33.9776 21.2768 35.4221 21.2768H47.9766Z"
                  fill="black"
                />
                <path
                  id="Vector_14"
                  d="M48.1569 7.43276C49.1784 6.41133 50.8345 6.41133 51.8559 7.43277C52.8773 8.4542 52.8773 10.1103 51.8559 11.1317L38.3449 24.6426C37.3236 25.664 35.6676 25.664 34.646 24.6426C33.6245 23.6212 33.6245 21.9651 34.646 20.9436L48.1569 7.43276Z"
                  fill="black"
                />
                <path
                  id="Vector_15"
                  d="M30.5172 23.8924C30.5172 25.337 29.3461 26.508 27.9017 26.508C26.4572 26.508 25.2861 25.337 25.2861 23.8924V11.3379C25.2861 9.89336 26.4572 8.72235 27.9017 8.72235C29.3461 8.72235 30.5172 9.89336 30.5172 11.3379V23.8924Z"
                  fill="black"
                />
                <path
                  id="Vector_16"
                  d="M15.3333 13.9535C13.8888 13.9535 12.7178 12.7824 12.7178 11.3379C12.7178 9.89336 13.8888 8.72235 15.3333 8.72235H27.8878C29.3324 8.72235 30.5034 9.89336 30.5034 11.3379C30.5034 12.7824 29.3324 13.9535 27.8878 13.9535H15.3333Z"
                  fill="black"
                />
                <path
                  id="Vector_17"
                  d="M15.1467 27.7974C14.1252 28.8188 12.4692 28.8188 11.4477 27.7974C10.4263 26.7759 10.4263 25.1199 11.4477 24.0984L24.9587 10.5876C25.98 9.56611 27.6362 9.56611 28.6575 10.5876C29.679 11.609 29.679 13.265 28.6575 14.2864L15.1467 27.7974Z"
                  fill="black"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
  mtn: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="option-06">
        <path
          id="Vector"
          d="M15.9998 10.25C9.5106 10.25 4.25 12.8246 4.25 16C4.25 19.1754 9.5106 21.75 15.9998 21.75C22.489 21.75 27.75 19.1754 27.75 16C27.75 12.8246 22.4886 10.25 15.9998 10.25ZM15.9998 20.8424C10.0229 20.8424 5.1777 18.6744 5.1777 16C5.1777 13.3256 10.0229 11.158 15.9998 11.158C21.9767 11.158 26.8223 13.3256 26.8223 16C26.8223 18.6744 21.9771 20.8424 15.9998 20.8424ZM14.5183 14.9915V14.0835H17.8344V14.9915H16.6406V17.9177H15.7117V14.9915H14.5183ZM21.8345 14.0835V17.9177H20.9068L19.2243 15.6357V17.9177H18.2971V14.0835H19.2243L20.9068 16.3655V14.0835H21.8345ZM10.1382 17.9177V14.0835H11.0659L12.0963 15.6348L13.1268 14.0835H14.0545V17.9177H13.1268V15.7485L12.4221 16.8069H11.7692L11.0646 15.7485V17.9177H10.1382Z"
          fill="black"
        />
      </g>
    </svg>
  ),
  coris: (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="coris-money-ico-48*48-black" style={{ mixBlendMode: "darken" }}>
        <g id="Frame 111">
          <g id="Clip path group">
            <mask
              id="mask0_7204_3556"
              style={{ maskType: "luminance" }}
              maskUnits="userSpaceOnUse"
              x="6"
              y="6"
              width="52"
              height="52"
            >
              <g id="clip0_7186_5037">
                <path
                  id="Vector"
                  d="M57.3327 6.66669H6.66602V57.3334H57.3327V6.66669Z"
                  fill="white"
                />
              </g>
            </mask>
            <g mask="url(#mask0_7204_3556)">
              <g id="coris-money-shit 1">
                <path
                  id="Vector_2"
                  d="M53.1104 6.66669H10.8882C8.55636 6.66669 6.66602 8.55703 6.66602 10.8889V53.1111C6.66602 55.443 8.55636 57.3334 10.8882 57.3334H53.1104C55.4423 57.3334 57.3327 55.443 57.3327 53.1111V10.8889C57.3327 8.55703 55.4423 6.66669 53.1104 6.66669Z"
                  fill="black"
                />
                <path
                  id="Vector_2_2"
                  d="M17.2705 38.4079C17.6772 25.0292 31.7817 13.9667 46.727 14.4677L46.703 14.6695C29.1269 15.9769 20.845 28.8543 20.0289 35.4927C18.9036 43.3392 24.6289 47.26 29.4318 47.0628C34.2346 46.8599 38.7546 44.1933 38.7546 44.1933L39.8317 46.2248C39.8317 46.2248 35.0786 49.5485 27.4126 49.5485H27.3566C22.0401 49.5481 17.5781 46.0231 17.2705 38.4079ZM29.6853 40.5223C30.2988 40.1711 31.0146 39.8675 31.472 39.5139C31.9338 39.1636 30.9626 38.4081 30.2481 38.1071C29.5341 37.8043 28.7764 37.236 28.5622 36.9511C28.2986 36.6001 28.2309 35.8972 28.3081 35.6936C28.4145 34.6539 31.3217 32.7267 32.2433 32.2723C33.1612 31.8203 37.3521 30.0093 37.657 29.8055C37.9652 29.6072 38.6044 29.2976 39.0637 28.9396C39.7298 28.3193 38.2184 27.3447 38.0641 27.1936C37.915 27.0409 37.4892 26.7544 36.8892 25.936C36.232 25.0292 36.3708 24.3568 36.4824 24.0217C37.195 21.1099 45.1677 20.0023 45.1677 20.0023L45.218 20.2037C43.0258 20.8064 40.7738 21.6605 39.496 22.364C38.2182 23.0708 37.8606 23.8248 37.7584 24.3276C37.4522 25.4309 39.7364 26.7455 40.3666 27.2415C41.01 27.7495 41.0756 28.094 41.1297 28.5507C41.1961 29.7321 38.0161 30.8664 36.9934 31.3185C34.6945 32.2243 31.8826 33.9825 31.1181 34.4363C30.35 34.8861 29.889 35.794 29.889 36.0923C29.7888 37.1492 31.5262 37.7068 32.1913 37.9599C32.8581 38.2071 33.2658 38.66 33.2129 39.3628C33.1612 40.0697 32.1384 40.5224 31.5262 40.7212C27.898 41.3256 24.7822 44.4924 24.7822 44.4924C25.1394 42.6828 29.0744 40.8748 29.6853 40.5223Z"
                  fill="white"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  ),
};
