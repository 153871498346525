export const COLORS = {
  bright: "--bright",
  light: "--light",
  extraLight: "--extra-light",
  black: "--black",
  extraDark: "--extra-dark",
  dark: "--dark",
  darkGray: "--dark-gray",
  white: "--white",
  lightGray: "--light-gray",
  gray: "--gray",
  lightRed: "--light-red",
  red: "--red",
  neutral: "--neutral",
  transparent: "--transparent",
};

export const BANKS = {
  ecoBank: {
    name: "Eco Bank",
    color: "--eco-bank",
  },
  ubaBank: {
    name: "UBA Bank",
    color: "--uba-bank",
  },
  corisBank: {
    name: "Coris Money",
    color: "--coris-bank",
  },
  orangeMoney: {
    name: "Orange Money",
    color: "--orange-money",
  },
  moovMoney: {
    name: "Moov Money",
    color: "--moov-money",
  },
  mtnMoney: {
    name: "MTN Money",
    color: "--mtn-money",
  },
};
