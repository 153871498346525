import React from "react";

const ExpandableInstructions = ({ title, content }) => {
  return (
    <details className="expandable">
      <summary>{title}</summary>
      {content}
    </details>
  );
};

export default ExpandableInstructions;
