import React, { useState } from "react";
import BackButton from "../components/BackButton";
import Input from "../components/form-controls/Input";
import Layout from "../components/layout/Layout";
import Note from "../components/Note";
import PopUp from "../components/PopUp";
import PrimaryButton from "../components/PrimaryButton";
import orangeLogo from "../assets/images/side-logos/orange_money.svg";
import verified from "../assets/images/verified.svg";
import error from "../assets/images/error_card.svg";
import { BANKS } from "../constants";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";
import { usePayload } from "../hooks/usePayload";
import { processCheckout } from "../service/api";

const Verification = ({ walletCode = "ORANGE_MONEY" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [notification, setNotification] = useState({
    img: verified,
    title: "",
    text: "",
  });
  const navigate = useNavigate();
  const { id, invoice, getRedirectionURL, getWallet } = useInvoice();
  const { payload, setPayload } = usePayload();

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const handleVerify = (e) => {
    e.preventDefault();
    setIsLoading(true);

    // send payload to server
    processCheckout({
      ...payload,
      invoice_id: id,
      payment_processor_id: getWallet(walletCode)?.public_id,
    })
      .then(
        (res) => {
          console.log(res);
          setNotification({
            img: verified,
            title: "Payment Successful",
            text: "The payment has been made successfully.",
          });
          setIsOpen(true);
          return res;
        },
        (err) => {
          setNotification({
            img: error,
            title: "Payment Unsuccessful",
            text:
              err.response?.message ??
              "The payment did not succeed because something went wrong.",
          });
          setIsOpen(true);
          setIsLoading(false);
          return err;
        }
      )
      .then(({ response }) => {
        if (response?.data.response?.redirect_url) {
          const queryarams = getRedirectionURL({
            transaction_id: response.data.response.transaction_id,
            payment_processor: "Orange Money",
            status: response.data.status === "SUCCESS" ? "true" : "false",
          });
          setTimeout(() => {
            window.location.href = `${response.data.response.redirect_url}?${queryarams}`;
          }, 3500);
        }
      });
  };
  return (
    <Layout
      secondHalf={{
        img: {
          src: orangeLogo,
          alt: BANKS.mtnMoney.name,
        },
        bgColor: BANKS.orangeMoney.color,
      }}
    >
      <BackButton onClick={() => navigate(-1)} />
      <h1 className="page-title">Verification.</h1>
      <p>
        Dial the USSD code below to complete this transaction with Orange money.
      </p>
      <Note
        className="otp-note"
        title="Note"
        content={
          <h5>
            <ul>
              <li>
                After dialing the code, You will receive an{" "}
                <b>OTP verification code</b> sent to your number.
              </li>
              <li>
                Copy that OTP code and paste it into the <b>OTP field</b> and
                then use <b>Verify button</b> for verification.
              </li>
            </ul>
          </h5>
        }
      />
      <form>
        <Input
          className="ussd-input"
          label="USSD code"
          fullWidth={true}
          value={`*866*4*6*${invoice?.amount}#`}
          allowCopy={true}
          bold={true}
        />
        <Input
          label="OTP"
          placeHolder="Type OTP here"
          type="number"
          fullWidth={true}
          name="otp"
          value={payload?.otp?.slice(0, 6) ?? ""}
          onChange={handleChange}
        />
        <PrimaryButton
          disabled={!payload?.otp || !(isLoading === null)}
          text={
            <div className="verify-btn">
              {isLoading && (
                <img
                  className="btn-loader"
                  src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_medium.gif"
                  alt="Verifying"
                />
              )}
              <span
                className="button-text"
                style={{ color: "inherit", cursor: "inherit" }}
              >
                {isLoading ? "Verifying" : "Verify"}
              </span>
            </div>
          }
          onClick={handleVerify}
        />
      </form>
      <PopUp
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        img={notification.img}
        title={notification.title}
        text={notification.text}
      />
    </Layout>
  );
};

export default Verification;
