import React, { useState, createContext, useContext } from "react";
import { objectToQueryString } from "../helperFunctions";
import { getInvoiceDetails } from "../service/api";

export const InvoiceContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const InvoiceProvider = ({ children }) => {
  const [id, setId] = useState(null);
  const [invoice, setInvoice] = useState(null);
  const [wallets, setWallets] = useState([]);
  const [result, setResult] = useState(null);
  const [transID, setTransID] = useState(null);

  const getInvoice = async (invoiceId) => {
    return getInvoiceDetails(invoiceId).then((res) => {
      setId(res.response.invoice_id);
      setInvoice(res.response.invoice_detail);
      setWallets(res.response.payment_processors.wallets ?? []);
      return res;
    });
  };

  const getRedirectionURL = (
    transactionResult = {
      transaction_id: null,
      payment_processor: null,
      status: null,
    }
  ) => {
    transactionResult = {
      ...transactionResult,
      invoice_id: id,
      amount: invoice?.amount,
      datetime: new Date().toISOString(),
    };
    setResult(transactionResult);
    return `${objectToQueryString(transactionResult)}`;
  };

  const getWallet = (walletCode) =>
    wallets.find((wallet) => wallet.code === walletCode);

  const value = {
    id,
    getInvoice,
    wallets,
    invoice,
    result,
    getRedirectionURL,
    getWallet,
    transID,
    setTransID,
  };
  return (
    <InvoiceContext.Provider value={value}>{children}</InvoiceContext.Provider>
  );
};

export const useInvoice = () => useContext(InvoiceContext);
