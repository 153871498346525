import React, { useState } from "react";

function IconButton({
  icon,
  iconColor = {
    normal: null,
    disabled: null,
    hover: null,
  },
  onClick,
  disabled = false,
  className = "",
  ...props
}) {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <button
      className={`icon-button ${className}`}
      disabled={disabled}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        fill: `var(${isHovered ? iconColor.hover : iconColor?.normal})`,
        "&:disabled": {
          fill: `var(${iconColor?.disabled})`,
        },
      }}
      {...props}
    >
      {icon}
    </button>
  );
}

export default IconButton;
