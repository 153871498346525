import { render } from "react-dom";
import { MemoryRouter } from "react-router-dom";
import "./index.css";
import "./animations.css";
import "./Customization.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { InvoiceProvider } from "./hooks/useInvoice";
import { PayloadProvider } from "./hooks/usePayload";

const rootElement = document.getElementById("root");
render(
  <MemoryRouter>
    <InvoiceProvider>
      <PayloadProvider>
        <App />
      </PayloadProvider>
    </InvoiceProvider>
  </MemoryRouter>,
  rootElement
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
