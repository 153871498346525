import React from "react";

function Card({ icon, text, onClick }) {
  return (
    <div className="card" onClick={onClick}>
      <div className="inner-items">
        {icon}
        <h3>{text}</h3>
      </div>
    </div>
  );
}

export default Card;
