import React from "react";
import sappayLogo from "../../assets/images/sappay-logo.svg";

function Layout({
  children,
  secondHalf = {
    img: {
      src: null,
      alt: null,
    },
    bgColor: null,
    content: null,
  },
}) {
  return (
    <div className="main">
      <div className="half first">
        <header>
          <img className="brand" src={sappayLogo} alt="sappay logo" srcSet="" />
        </header>
        <main>{children}</main>
        <small className="copy-right">
          All rights reserved SAPPAY © {new Date().getFullYear()}
        </small>
      </div>
      <div
        className="half second"
        style={{
          backgroundColor: `var(${secondHalf.bgColor})`,
        }}
      >
        {secondHalf.img && (
          <img src={secondHalf.img.src} alt={secondHalf.img.alt} srcSet="" />
        )}
        {secondHalf.content && (
          <div className="content">{secondHalf.content}</div>
        )}
      </div>
    </div>
  );
}

export default Layout;
