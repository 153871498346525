import "./App.css";
import { Routes, Route } from "react-router-dom";
import Components from "./pages/Components";
import PaymentOptions from "./pages/PaymentOptions";
import OrangeMoney from "./pages/OrangeMoney";
import Verification from "./pages/Verification";
import NotFound from "./pages/NotFound";
import Summary from "./pages/Summary";
import MoovMoney from "./pages/MoovMoney";
import MoovVerification from "./pages/MoovVerification";
import CorisMoney from "./pages/CorisMoney";
import CorisVerification from "./pages/CorisVerification";

function App() {
  return (
    <Routes>
      <Route path="/" element={<PaymentOptions />} />
      <Route path="/orange-money" element={<OrangeMoney />} />
      <Route path="/moov-money" element={<MoovMoney />} />
      <Route path="/components" element={<Components />} />
      <Route path="/verification" element={<Verification />} />
      <Route path="/moov-verification" element={<MoovVerification />} />
      <Route path="/coris-money" element={<CorisMoney />} />
      <Route path="/coris-verification" element={<CorisVerification />} />
      <Route path="/summary" element={<Summary />} />
      <Route path="/not-found" element={<NotFound />} />
    </Routes>
  );
}

export default App;
