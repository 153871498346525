import React, { useState } from "react";
import bankLogo from "../assets/images/side-logos/moov_money.svg";
import BackButton from "../components/BackButton";
import Layout from "../components/layout/Layout";
import { BANKS } from "../constants";
import Input from "../components/form-controls/Input";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";
import { usePayload } from "../hooks/usePayload";
import { getOTP } from "../service/api";
import verified from "../assets/images/verified.svg";
import PopUp from "../components/PopUp";
import errorImg from "../assets/images/error_card.svg";

const moovStatusMap = {
  0: {
    ok: true,
    message: "OTP sent Successfully",
  },
  4: {
    ok: false,
    message: "You are not authorized to perform this transaction! are you sure you are registered?",
  },
  12: {
    ok: false,
    message: "Minimum amount is 100",
  },
}

function MoovMoney() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [notification, setNotification] = useState({
    img: verified,
    title: "",
    text: "",
  });
  const { id, invoice, getWallet, setTransID } = useInvoice();
  const navigate = useNavigate();
  const { payload, setPayload } = usePayload();

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const performCheckout = (e) => {
    e.preventDefault();
    setIsLoading(true);
    getOTP({
      ...payload,
      invoice_id: id,
      payment_processor_id: getWallet("MOOV_MONEY")?.public_id,
    })
      .then((response) => {
        if (moovStatusMap[response.response.status]?.ok) {
          setTransID(response.response.trans_id);
          navigate("/moov-verification");
        } else {
          setNotification({
            img: errorImg,
            title: "Payment Pending",
            text: moovStatusMap[response.response.status]?.message ?? 'Something went wrong',
          });
          setIsOpen(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setNotification({
          img: errorImg,
          title: "Payment Pending",
          text: "Your payment is still pending, make sure you have dialed the USSD code & approved the transaction",
        });
        setIsOpen(true);
        setIsLoading(false);
      });

    //
  };

  return (
    <Layout
      secondHalf={{
        img: {
          src: bankLogo,
          alt: BANKS.moovMoney.name,
        },
        bgColor: BANKS.moovMoney.color,
      }}
    >
      <BackButton to="/" />
      <h1 className="page-title">{BANKS.moovMoney.name}.</h1>
      <p>Enter your wallet details.</p>
      <form>
        <Input
          label="Wallet Name"
          fullWidth={true}
          value={BANKS.moovMoney.name}
          disabled={true}
        />
        <Input
          label="REGISTERED PHONE NUMBER"
          fullWidth={true}
          name="customer_msisdn"
          value={payload?.customer_msisdn ?? ""}
          onChange={handleChange}
        />
        <Input
          label="Amount"
          type="number"
          fullWidth={true}
          value={invoice?.amount}
          disabled={true}
        />
        <PrimaryButton
          disabled={!payload?.customer_msisdn || isLoading}
          text={
            <div className="verify-btn">
              {isLoading && (
                <img
                  className="btn-loader"
                  src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_medium.gif"
                  alt="Verifying"
                />
              )}
              <span
                className="button-text"
                style={{ color: "inherit", cursor: "inherit" }}
              >
                {isLoading ? "Continuing" : "Continue"}
              </span>
            </div>
          }
          onClick={performCheckout}
        />
      </form>
      <PopUp
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        img={notification.img}
        title={notification.title}
        text={notification.text}
      />
    </Layout>
  );
}

export default MoovMoney;
