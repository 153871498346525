import React, { useState } from "react";
import corisBank from "../assets/images/coris_bank.svg";
import BackButton from "../components/BackButton";
import Layout from "../components/layout/Layout";
import { BANKS } from "../constants";
import Input from "../components/form-controls/Input";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";
import { usePayload } from "../hooks/usePayload";
import { getOTP } from "../service/api";
import verified from "../assets/images/verified.svg";
import errorImg from "../assets/images/error_card.svg";
import PopUp from "../components/PopUp";

const corisStatusMap = {
  "Opération éffectuée avec succès !": {
    ok: true,
    message: "OTP sent Successfully",
  },
}

function CorisMoney() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const navigate = useNavigate();
  const { invoice, id, getWallet, setTransID } = useInvoice();
  const { payload, setPayload } = usePayload();
  const [notification, setNotification] = useState({
    img: verified,
    title: "",
    text: "",
  });

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const performCheckout = (e) => {
    e.preventDefault();
    setIsLoading(true);
    getOTP({
      ...payload,
      invoice_id: id,
      payment_processor_id: getWallet("CORIS_MONEY")?.public_id,
    })
      .then((response) => {
        if (corisStatusMap[response.response.message]?.ok) {
          setTransID(response.response.trans_id);
          navigate("/coris-verification");
        } else {
          setNotification({
            img: errorImg,
            title: "Payment Pending",
            text: corisStatusMap[response.response.message]?.message ?? 'Something went wrong',
          });
          setIsOpen(true);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("chatching....");
        setNotification({
          img: errorImg,
          title: "Payment Pending",
          text: "Your payment is still pending, make sure you have dialed the USSD code & approved the transaction",
        });
        setIsOpen(true);
        setIsLoading(false);
      });

    //
  };

  return (
    <Layout
      secondHalf={{
        img: {
          src: corisBank,
          alt: BANKS.corisBank.name,
        },
        bgColor: BANKS.corisBank.color,
      }}
    >
      <BackButton to="/" />
      <h1 className="page-title">{BANKS.corisBank.name}.</h1>

      <form>
        <Input
          label="Bank Name"
          fullWidth={true}
          value={BANKS.corisBank.name}
          disabled={true}
        />
        <Input
          label="REGISTERED PHONE NUMBER"
          fullWidth={true}
          name="customer_msisdn"
          value={payload?.customer_msisdn ?? ""}
          onChange={handleChange}
        />
        <Input
          label="Amount"
          type="number"
          fullWidth={true}
          value={invoice?.amount}
          disabled={true}
        />

        <PrimaryButton
          disabled={!payload?.customer_msisdn || isLoading}
          text={
            <div className="verify-btn">
              {isLoading && (
                <img
                  className="btn-loader"
                  src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_medium.gif"
                  alt="Verifying"
                />
              )}
              <span
                className="button-text"
                style={{ color: "inherit", cursor: "inherit" }}
              >
                {isLoading ? "Continuing" : "Continue"}
              </span>
            </div>
          }
          onClick={performCheckout}
        />
      </form>
      <PopUp
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        img={notification.img}
        title={notification.title}
        text={notification.text}
      />
    </Layout>
  );
}

export default CorisMoney;
