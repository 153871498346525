import React from "react";
import BackButton from "../components/BackButton";
import Card from "../components/Card";
import ExpandableInstructions from "../components/ExpandableInstructions";
import Input from "../components/form-controls/Input";
import IconButton from "../components/IconButton";
import Note from "../components/Note";
import PopUp from "../components/PopUp";
import PrimaryButton from "../components/PrimaryButton";
import SecondaryButton from "../components/SecondaryButton";
import { COLORS } from "../constants";
import { ICONS } from "../icons";
import payments from "../assets/images/payments.svg";
import CountDown from "../components/CountDown";

const Components = () => {
  return (
    <>
      <h1>H1</h1>
      <h2>H2</h2>
      <h3>H3</h3>
      <p>peragraph</p>
      <label>label</label>
      <h5>Teams Card body</h5>
      <h6>Transection Card body</h6>
      <PrimaryButton
        text="Button"
        onClick={() => console.log("button")}
        // disabled={true}
      />
      <SecondaryButton
        text="Button"
        onClick={() => console.log("button")}
        disabled={true}
      />
      <BackButton to="/somehing" />
      <BackButton onClick={() => console.log("back click")} />
      <IconButton
        icon={ICONS.contentCopy}
        onClick={() => console.log("back click")}
      />
      <ExpandableInstructions
        title="How to pay with ECO Bank USSD?"
        content={
          <h5>
            To pay with ECO Bank USSD, you need to do the following:
            <br />
            <ul>
              <li>Dial *326# with your registered phone number.</li>
              <li>
                Follow the on screen prompts and create your authentication PIN.
              </li>
            </ul>
            <br />
            Once you confirm that you can pay with ECO Bank USSD:
            <br />
            <ul>
              <li>Dial *326*33*4*617649#</li>
              <li>
                Follow the on-screen prompts and complete the transaction with
                your PIN or password.
              </li>
            </ul>
          </h5>
        }
      />
      <Note title="Note" content={<h5>This is a note</h5>} />
      <Card
        icon={ICONS.creditCard}
        text="Card"
        onClick={() => console.log("crd")}
      />
      <Input
        // value="input"
        label="label"
        placeHolder="input"
        onChange={() => console.log("input")}
      />
      <PopUp
        isOpen={false}
        img={payments}
        text="Please authenticate with your bank by clicking the button below."
        content={
          <div>
            <PrimaryButton
              text="Button"
              onClick={() => console.log("button")}
              // disabled={true}
              bgColor={{
                normal: COLORS.light,
              }}
              textColor={{
                normal: COLORS.black,
                hover: COLORS.white,
              }}
            />
            <p className="support-text">or</p>
            <SecondaryButton
              textColor={{
                normal: COLORS.extraLight,
                hover: COLORS.light,
              }}
              borderColor={{
                normal: COLORS.extraLight,
                hover: COLORS.light,
              }}
              text="Button"
              onClick={() => console.log("button")}
            />
          </div>
        }
      />
      <CountDown seconds={60} />
    </>
  );
};

export default Components;
