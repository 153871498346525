import React, { useState, useEffect } from "react";
import { COLORS } from "../constants";
import { ICONS } from "../icons";
import IconButton from "./IconButton";

const PopUp = ({ img, title, text, content, onClose, isOpen = true }) => {
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  const handleClose = () => {
    setOpen(false);
    onClose();
  };

  return (
    <div
      className="pop-up-continer"
      onClick={handleClose}
      style={{
        pointerEvents: open ? "all" : "none",
        display: open ? "block" : "hidden",
        opacity: open ? 1 : 0,
      }}
    >
      <div className="pop-up">
        <IconButton
          className="close-button"
          icon={ICONS.close}
          iconColor={{
            normal: COLORS.white,
            hover: COLORS.light,
          }}
          onClick={handleClose}
        />
        <div className="inner-content">
          {img && <img className="pop-up-img" src={img} alt={title} />}
          {title && <h2>{title}</h2>}
          {text && <p>{text}</p>}
          {content}
        </div>
      </div>
    </div>
  );
};

export default PopUp;
