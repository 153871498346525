import React, { useState, useEffect } from "react";

function CountDown({ seconds = 0 }) {
  const [timeInSeconds, setTimeInSeconds] = useState(seconds);
  const calculateTimeLeft = () => {
    return {
      minutes: String(Math.floor(timeInSeconds / 60)).padStart(2, "0"),
      seconds: String(timeInSeconds % 60).padStart(2, "0"),
    };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    var timer;
    if (timeInSeconds >= 0) {
      timer = setTimeout(() => {
        setTimeInSeconds(timeInSeconds - 1);
        setTimeLeft(calculateTimeLeft());
      }, 1000);
    }
    return () => clearTimeout(timer);
  });

  return (
    <div className="count-down">
      <h4>{timeLeft.minutes}</h4>
      <h4>{timeLeft.seconds}</h4>
    </div>
  );
}

export default CountDown;
