import React from "react";
import { Link, useLocation } from "react-router-dom";
import { ICONS } from "../icons";

const BackButton = ({ onClick, to }) => {
  const { pathname } = useLocation();
  // eslint-disable-next-line
  if (pathname != "/") {
    if (onClick) {
      return (
        <button className="back-button" onClick={onClick}>
          {ICONS.back}Back
        </button>
      );
    }

    return (
      <Link className="back-button" to={to}>
        {ICONS.back}Back
      </Link>
    );
  }
  return null;
};

export default BackButton;
