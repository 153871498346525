// convert object to query string
function objectToQueryString(obj) {
  return Object.keys(obj)
    .map(function (key) {
      return [key, obj[key]].map(encodeURIComponent).join("=");
    })
    .join("&");
}

module.exports = {
  objectToQueryString,
};
