import api from "./api-client";

export const getInvoiceDetails = (invoiceId) =>
  api
    .get(`/checkout/initiate/?invoice_id=${invoiceId}`)
    .then((res) => res.data);

export const getStatus = (invoiceId) =>
  api.get(`/transaction_status/?invoice=${invoiceId}`).then((res) => res.data);

export const getOTP = (OPTPayload) =>
  api.post(`/checkout/get-otp/`, OPTPayload).then((res) => res.data);

export const processCheckout = (checkoutPayload) =>
  api.post("/checkout/perform/", checkoutPayload).then((res) => res.data);

export default api;
