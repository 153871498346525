import React from "react";
import orangeLogo from "../assets/images/side-logos/orange_money.svg";
import BackButton from "../components/BackButton";
import Layout from "../components/layout/Layout";
import { BANKS } from "../constants";
import Input from "../components/form-controls/Input";
import PrimaryButton from "../components/PrimaryButton";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";
import { usePayload } from "../hooks/usePayload";

function OrangeMoney() {
  const navigate = useNavigate();
  const { invoice } = useInvoice();
  const { payload, setPayload } = usePayload();

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  return (
    <Layout
      secondHalf={{
        img: {
          src: orangeLogo,
          alt: BANKS.mtnMoney.name,
        },
        bgColor: BANKS.orangeMoney.color,
      }}
    >
      <BackButton to="/" />
      <h1 className="page-title">Orange Money.</h1>
      <p>Enter your wallet details.</p>
      <form>
        <Input
          label="Wallet Name"
          fullWidth={true}
          value="Orange Money"
          disabled={true}
        />
        <Input
          label="REGISTERED PHONE NUMBER"
          fullWidth={true}
          name="customer_msisdn"
          value={payload?.customer_msisdn ?? ""}
          onChange={handleChange}
        />
        <Input
          label="Amount"
          type="number"
          fullWidth={true}
          value={invoice?.amount}
          disabled={true}
        />
        <PrimaryButton
          disabled={!payload?.customer_msisdn}
          text="Continue"
          onClick={() => navigate("/verification")}
        />
      </form>
    </Layout>
  );
}

export default OrangeMoney;
