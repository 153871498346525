import React, { useEffect } from "react";
import { COLORS } from "../constants";
import BackButton from "../components/BackButton";
import { ICONS } from "../icons";
import Card from "../components/Card";
import Layout from "../components/layout/Layout";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";

const WALLETS = [
  {
    name: "Orange Money",
    icon: ICONS.orange,
    to: "/orange-money",
  },
  {
    name: "Moov Money",
    icon: ICONS.moov,
    to: "/moov-money",
  },
  {
    name: "Coris Money",
    icon: ICONS.coris,
    to: "/coris-money",
  },
];

function PaymentOptions() {
  const navigate = useNavigate();
  const { getInvoice } = useInvoice();

  useEffect(() => {
    const invoice_id = window.location.pathname.split("/")[1];
    getInvoice(invoice_id).catch((err) => {
      navigate("/not-found", {
        replace: true,
        state: { error: err.response.data?.message || err.message },
      });
    });
    // eslint-disable-next-line
  }, [window.location.pathname]);

  return (
    <Layout
      secondHalf={{
        bgColor: COLORS.light,
        content: (
          <svg
            id="wallet-svg"
            xmlns="http://www.w3.org/2000/svg"
            width="586"
            height="373"
            viewBox="0 0 586 373"
            fill="none"
          >
            <g id="wallet">
              <g clipPath="url(#clip0_645_3563)">
                <rect
                  y="0.00500488"
                  width="586"
                  height="372"
                  rx="50"
                  fill="#2A063D"
                />
                <path
                  id="noteOne"
                  d="M35 88.005L518 119.005L541 285.005H35V88.005Z"
                  fill="#8E15CC"
                />
                <path
                  id="noteTwo"
                  d="M43 117.005L526 148.005L549 314.005H43V117.005Z"
                  fill="#9C6BFE"
                />
                <path
                  id="Rectangle 9"
                  d="M0 94.005L86 160.005H291.5H515L586 94.005V322.005C586 349.619 563.614 372.005 536 372.005H50C22.3858 372.005 0 349.619 0 322.005V94.005Z"
                  fill="#45125F"
                />
              </g>
            </g>
            <defs>
              <clipPath id="clip0_645_3563">
                <rect
                  y="0.00500488"
                  width="586"
                  height="372"
                  rx="50"
                  fill="white"
                />
              </clipPath>
            </defs>
          </svg>
        ),
      }}
    >
      <BackButton to="/" />
      <h1 className="page-title">Wallet.</h1>
      <p>Choose a wallet to continue.</p>
      <div className="wallets">
        {WALLETS.map((wallet) => (
          <Card
            key={wallet.name}
            icon={wallet.icon}
            text={wallet.name}
            onClick={() => navigate(wallet.to)}
          />
        ))}
      </div>
    </Layout>
  );
}

export default PaymentOptions;
