import React, { useState } from "react";

const SecondaryButton = ({
  text,
  onClick,
  disabled = false,
  textColor = {
    normal: null,
    hover: null,
    disabled: null,
  },
  bgColor = {
    normal: null,
    hover: null,
    disabled: null,
  },
  borderColor = {
    normal: null,
    hover: null,
    disabled: null,
  },
  className,
  ...props
}) => {
  const [isHovered, setIsHovered] = useState(false);
  return (
    <button
      className={`secondary-button ${className}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        color: isHovered
          ? `var(${textColor?.hover})`
          : `var(${textColor?.normal})`,
        backgroundColor: isHovered
          ? `var(${bgColor?.hover})`
          : `var(${bgColor?.normal})`,
        borderColor: isHovered
          ? `var(${borderColor?.hover})`
          : `var(${borderColor?.normal})`,
      }}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
