import React, { useState, createContext, useContext } from "react";

export const PayloadContext = createContext(null);

// eslint-disable-next-line react/prop-types
export const PayloadProvider = ({ children }) => {
  const [payload, setPayload] = useState({});

  const value = { payload, setPayload };
  return (
    <PayloadContext.Provider value={value}>{children}</PayloadContext.Provider>
  );
};

export const usePayload = () => useContext(PayloadContext);
