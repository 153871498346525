import React from "react";

function Note({ title = "Note", content, className = "" }) {
  return (
    <div className={`note ${className}`}>
      <h4>{title}</h4>
      <div className="note-content">{content}</div>
    </div>
  );
}

export default Note;
