import React, { useState } from "react";
import BackButton from "../components/BackButton";
import Input from "../components/form-controls/Input";
import Layout from "../components/layout/Layout";
import PopUp from "../components/PopUp";
import PrimaryButton from "../components/PrimaryButton";
import corisBank from "../assets/images/coris_bank.svg";
import verified from "../assets/images/verified.svg";
import { BANKS } from "../constants";
import { useNavigate } from "react-router-dom";
import { useInvoice } from "../hooks/useInvoice";
import { usePayload } from "../hooks/usePayload";
import { processCheckout } from "../service/api";
import Note from "../components/Note";
import errorImg from "../assets/images/error_card.svg";

const CorisVerification = ({ walletCode = "CORIS_MONEY" }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  const [isError, setIsError] = useState(false);
  const [notification, setNotification] = useState({
    img: verified,
    title: "",
    text: "",
  });
  const navigate = useNavigate();
  const { id, getWallet, getRedirectionURL, transID } = useInvoice();
  const { payload, setPayload } = usePayload();

  const handleChange = (e) =>
    setPayload({ ...payload, [e.target.name]: e.target.value });

  const handleVerify = (e) => {
    setIsLoading(true);
    e.preventDefault();

    // send payload to server
    processCheckout({
      ...payload,
      invoice_id: id,
      payment_processor_id: getWallet(walletCode)?.public_id,
      trans_id: transID,
    })
      .then(
        (res) => {
          setNotification({
            img: verified,
            title: "Payment Successful",
            text: "The payment has been made successfully.",
          });
          setIsOpen(true);
          return res;
        },
        (err) => {
          setNotification({
            img: errorImg,
            title: "Payment Unsuccessful",
            text:
              err.response?.message ??
              "The payment did not succeed because something went wrong.",
          });
          setIsOpen(true);
          setIsLoading(false);
          setIsError(true);
          return err;
        }
      )
      .then(({ response }) => {
        if (response?.redirect_url) {
          const queryarams = getRedirectionURL({
            transaction_id: response.transaction_id,
            payment_processor: "Coris Money",
            status: response.status === "SUCCESS" ? "true" : "false",
          });
          setTimeout(() => {
            window.location.href = `${response.redirect_url}?${queryarams}`;
          }, 3500);
        }
      });
  };

  return (
    <Layout
      secondHalf={{
        img: {
          src: corisBank,
          alt: BANKS.corisBank.name,
        },
        bgColor: BANKS.corisBank.color,
      }}
    >
      <BackButton onClick={() => navigate(-1)} />
      <h1 className="page-title">Verification.</h1>

      <p>
        Enter the 6-digit verification code sent to{" "}
        <b style={{ color: "rgba(156, 107, 254, 1)" }}>
          {payload.customer_msisdn}
        </b>
        .
      </p>
      <Note
        className="otp-note"
        title="Note"
        content={
          <h5>
            <ul>
              <li>
                The OTP verification code will get expired after 30 minutes.
              </li>
              <li>If you didn’t receive the OTP then try the Resend button.</li>
            </ul>
          </h5>
        }
      />
      <form>
        <Input
          label="OTP"
          name="otp"
          value={payload?.otp}
          onChange={handleChange}
          fullWidth={true}
        />
        <PrimaryButton
          disabled={isError || isLoading || !payload?.otp}
          text={
            <div className="verify-btn">
              {isLoading && (
                <img
                  className="btn-loader"
                  src="https://raw.githubusercontent.com/Codelessly/FlutterLoadingGIFs/master/packages/cupertino_activity_indicator_square_medium.gif"
                  alt="Verifying"
                />
              )}
              <span
                className="button-text"
                style={{ color: "inherit", cursor: "inherit" }}
              >
                {isLoading ? "Verifying" : "Verify"}
              </span>
            </div>
          }
          onClick={handleVerify}
        />
      </form>
      <PopUp
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        img={notification.img}
        title={notification.title}
        text={notification.text}
      />
    </Layout>
  );
};

export default CorisVerification;
